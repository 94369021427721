/**
 * Part of Genesis application
 *
 * @copyright 2022 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

export const VoucherFolioSeries = {
    async get(id) {
        const response = await axios.get(`/api/voucher_folio_series/${id}`, { responseType: 'json' })
        return response.data.data
    },
    async list(params) {
        const response = await axios.get('/api/voucher_folio_series', { responseType: 'json', params })
        return response.data.data
    },
    async store(data) {
        const response = await axios.post('/api/voucher_folio_series', data, { responseType: 'json' })
        return response.data
    },
    async update(data) {
        const response = await axios.patch(`/api/voucher_folio_series/${data.id}`, data, { responseType: 'json' })
        return response.data.data
    },
    async delete(data) {
        await axios.delete(`/api/voucher_folio_series/${data.id}`, { responseType: 'json', params: data })
    },
    async viewModel() {
        const response = await axios.get('/api/voucher_folio_series/view_model', { responseType: 'json' })
        return response.data
    },
}

export default VoucherFolioSeries