/**
 * Part of Genesis application
 *
 * @copyright 2021 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

/** @module genesis/traffic/trailer */

/**
 * @typedef {Object} TrailerData
 * @property {?string} [brand]
 * @property {?number} [id]
 * @property {?boolean} [is_active]
 * @property {?string} [last_inspection_date]
 * @property {?string} [license_plate]
 * @property {?number} [lock_version]
 * @property {string} measure
 * @property {string} name
 * @property {?string} [owner]
 * @property {number} trailer_ownership_id
 */

/**
 * @typedef {Object} CatalogItemData
 * @property {number} id
 * @property {string} name
 */

/**
 * @typedef {Object} TrailerViewModel
 * @property {Object} catalogs
 * @property {CatalogItemData[]} catalogs.freight_trailer_ownership
 */

/** Genesis traffic trailer service module */
export const Trailer = {
    /**
     * Delete the specified trailer.
     *
     * @param {TrailerData} data The trailer to delete.
     * @return {Promise<void>}
     */
    async destroy(data) {
        await axios.delete(`/api/freight_trailers/${data.id}`, { data, responseType: 'json' })
    },
    /**
     * Returns the list of trailers.
     *
     * @return {Promise<TrailerData[]>}
     */
    async list() {
        const response = await axios.get('/api/freight_trailers', { responseType: 'json' })
        return response.data.data
    },
    async get(trailer_id) {
        const response = await axios.get(`/api/freight_trailers/${trailer_id}`, { responseType: 'json' })
        return response.data.data
    },
    /**
     * Stores a new trailer and returns the assigned ID.
     *
     * @param {TrailerData} data The source data to store.
     * @return {Promise<number>} The assigned identity number.
     */
    async store(data) {
        const response = await axios.post('/api/freight_trailers', data, { responseType: 'json' })
        return response.data
    },
    /**
     * Updates the specified trailer and returns the updated data.
     *
     * @param {TrailerData} data The source data to save.
     * @return {Promise<TrailerData>} The updated trailer data.
     */
    async update(data) {
        const response = await axios.patch(`/api/freight_trailers/${data.id}`, data, { responseType: 'json' })
        return response.data.data
    },
    async updateStatus(data) {
        const response = await axios.patch(
            `/api/freight_trailers/${data.id}/update_status`,
            data,
            { responseType: 'json' },
        )
        return response.data.data
    },
    /**
     * Fetches the trailer view model data.
     *
     * @return {Promise<TrailerViewModel>}
     */
    async viewModel() {
        const response = await axios.get('/api/freight_trailers/view_model', { responseType: 'json' })
        return response.data
    },
}

export default Trailer