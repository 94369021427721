/**
 * Part of Genesis application
 *
 * @copyright 2020 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import UserAuthorization from './UserAuthorization'

const statusAllowsCreateFreightTrip = {
    [Laravel.FreightMovementStatusType.ID_OPEN]: true,
    [Laravel.FreightMovementStatusType.ID_IN_ORIGIN]: false,
    [Laravel.FreightMovementStatusType.ID_IN_TRANSIT]: false,
    [Laravel.FreightMovementStatusType.ID_IN_PRECOS]: false,
    [Laravel.FreightMovementStatusType.ID_IN_CUSTOMS_MEX]: false,
    [Laravel.FreightMovementStatusType.ID_IN_CUSTOMS_FULL_INSPECTION_MEX]: false,
    [Laravel.FreightMovementStatusType.ID_IN_CUSTOMS_USA]: false,
    [Laravel.FreightMovementStatusType.ID_IN_CUSTOMS_FULL_INSPECTION_USA]: false,
    [Laravel.FreightMovementStatusType.ID_IN_DESTINATION]: false,
    [Laravel.FreightMovementStatusType.ID_COMPLETE]: false,
    [Laravel.FreightMovementStatusType.ID_FALSE]: true,
    [Laravel.FreightMovementStatusType.ID_LOCKED]: false,
    [Laravel.FreightMovementStatusType.ID_CANCELED]: true,
    [Laravel.FreightMovementStatusType.ID_RESERVED]: false,
    [Laravel.FreightMovementStatusType.ID_STAMPED]: false,
    [Laravel.FreightMovementStatusType.ID_CLOSED]: false,
}

class FreightTripStatus {
    constructor(status_id) {
        this._status_id = status_id
    }

    allowsUpdate() {
        return ! Laravel.FreightMovementStatusType.StatusUpdateDisallowed.filter(
            item => (item !== Laravel.FreightMovementStatusType.ID_COMPLETE &&
                    item !== Laravel.FreightMovementStatusType.ID_FALSE) ||
                (item === Laravel.FreightMovementStatusType.ID_COMPLETE &&
                    ! UserAuthorization.can(Laravel.Ability.UPDATE_COMPLETE_MOVEMENT, Laravel.Model.Shipment)) ||
                (item === Laravel.FreightMovementStatusType.ID_FALSE &&
                    ! UserAuthorization.can(Laravel.Ability.UPDATE_FALSE_MOVEMENT, Laravel.Model.Shipment)),
        ).includes(this._status_id)
    }

    getAvailableStatus() {
        let availableStatus = []
        const model = Laravel.Model.Shipment
        switch (this._status_id) {
            case Laravel.FreightMovementStatusType.ID_OPEN:
                if (UserAuthorization.can(Laravel.Ability.CANCEL_IN_OPEN_STATUS, model)) {
                    availableStatus.push(Laravel.FreightMovementStatusType.ID_CANCELED)
                }
                if (UserAuthorization.can(Laravel.Ability.SET_FALSE_START_IN_OPEN_STATUS, model)) {
                    availableStatus.push(Laravel.FreightMovementStatusType.ID_FALSE)
                }
                break
            case Laravel.FreightMovementStatusType.ID_IN_ORIGIN:
                if (UserAuthorization.can(Laravel.Ability.CANCEL_IN_ORIGIN_STATUS, model)) {
                    availableStatus.push(Laravel.FreightMovementStatusType.ID_CANCELED)
                }
                if (UserAuthorization.can(Laravel.Ability.SET_FALSE_START_IN_ORIGIN_STATUS, model)) {
                    availableStatus.push(Laravel.FreightMovementStatusType.ID_FALSE)
                }
                break
            case Laravel.FreightMovementStatusType.ID_IN_CUSTOMS_MEX:
                if (UserAuthorization.can(Laravel.Ability.SET_FALSE_START_IN_CUSTOMS_MEX_STATUS, model)) {
                    availableStatus.push(Laravel.FreightMovementStatusType.ID_FALSE)
                }
                if (UserAuthorization.can(Laravel.Ability.SET_FULL_INSPECTION_MEX_IN_CUSTOMS_MEX_STATUS, model)) {
                    availableStatus.push(Laravel.FreightMovementStatusType.ID_IN_CUSTOMS_FULL_INSPECTION_MEX)
                }
                break
        }
        return availableStatus
    }

    allowsCreateFreightTrip() {
        return statusAllowsCreateFreightTrip[this._status_id]
    }

    allowsCompleteFreightTrip() {
        return this._status_id === Laravel.FreightMovementStatusType.ID_IN_DESTINATION
    }

    allowsCancelCompletedFreightTrip() {
        return this._status_id === Laravel.FreightMovementStatusType.ID_COMPLETE
    }

    allowsRevertFreightTripStatus() {
        const model = Laravel.Model.Shipment
        return (
            this._status_id === Laravel.FreightMovementStatusType.ID_CANCELED &&
            UserAuthorization.can(Laravel.Ability.FREIGHT_TRIP_REVERT_CANCELLED_STATUS, model)
        ) || (
            this._status_id === Laravel.FreightMovementStatusType.ID_CLOSED &&
            UserAuthorization.can(Laravel.Ability.FREIGHT_TRIP_REVERT_CLOSED_STATUS, model)
        ) || (
            this._status_id === Laravel.FreightMovementStatusType.ID_COMPLETE &&
            UserAuthorization.can(Laravel.Ability.FREIGHT_TRIP_REVERT_COMPLETED_STATUS, model)
        ) || (
            this._status_id === Laravel.FreightMovementStatusType.ID_FALSE &&
            UserAuthorization.can(Laravel.Ability.FREIGHT_TRIP_REVERT_FALSE_STATUS, model)
        )
    }

    allowsCreateDeparturePass() {
        return [
                Laravel.FreightMovementStatusType.ID_OPEN,
                Laravel.FreightMovementStatusType.ID_IN_ORIGIN,
                Laravel.FreightMovementStatusType.ID_IN_TRANSIT,
                Laravel.FreightMovementStatusType.ID_IN_PRECOS,
                Laravel.FreightMovementStatusType.ID_IN_CUSTOMS_MEX,
                Laravel.FreightMovementStatusType.ID_IN_CUSTOMS_USA,
            ].includes(this._status_id)
    }

    allowsTrackUnit() {
        const allowedStatus = [
            Laravel.FreightMovementStatusType.ID_IN_ORIGIN,
            Laravel.FreightMovementStatusType.ID_IN_TRANSIT,
            Laravel.FreightMovementStatusType.ID_IN_PRECOS,
            Laravel.FreightMovementStatusType.ID_IN_CUSTOMS_MEX,
            Laravel.FreightMovementStatusType.ID_IN_CUSTOMS_USA,
        ]
        return allowedStatus.includes(this._status_id)
    }
}

export default FreightTripStatus
