/**
 * Part of Genesis application
 *
 * @copyright 2022 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

export const Customer = {
    async get(customer_group_id, customer_id) {
        const response = await axios.get(`/api/customer_groups/${customer_group_id}/customers/${customer_id}`)

        return response.data.data
    },
    async update(customer) {
        const response = await axios.patch(
            `/api/customer_groups/${customer.invoicing_customer_group_id}/customers/${customer.id}`,
            customer,
        )

        return response.data.data
    },
    async viewModel() {
        const response = await axios.get(`/api/customer_groups/customers/view_model`)

        return response.data
    },
}

export default Customer