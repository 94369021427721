/**
 * Part of Genesis application
 *
 * @copyright 2020 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import Echo from 'laravel-echo'

import Pusher from 'pusher-js'

window.Pusher = Pusher

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: Laravel.pusher.key,
    cluster: Laravel.pusher.cluster,
    wsHost: Laravel.pusher.host ? Laravel.pusher.host : `ws-${Laravel.pusher.cluster}.pusher.com`,
    wsPort: Laravel.pusher.port ?? 80,
    wssPort: Laravel.pusher.port ?? 443,
    forceTLS: (Laravel.pusher.scheme ?? 'https') === 'https',
    enabledTransports: ['ws', 'wss'],
})
