/**
 * Part of Genesis application
 *
 * @copyright 2022 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import journalAccount from './journalAccount'
import journalVoucherExtract from './journalVoucherExtract'

export const Accounting = {
    journalAccount,
    journalVoucherExtract,
}

export default Accounting