/**
 * Part of Genesis application
 *
 * @copyright 2021 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import {cloneDeep} from 'lodash'
import axios from 'axios'
import price from './price'

function transformTripTypes(tripTypes) {
    return tripTypes.map((tripType) => {
        tripType.product_service_code = tripType.product_service.code
        tripType.measurement_unit_code = tripType.measurement_unit.code
        delete tripType.product_service
        delete tripType.measurement_unit
        return tripType
    })
}
export const CustomerVariant = {
    price,
    async delete(customerVariant) {
        await axios.delete(
            `/api/freight_types/${customerVariant.freight_type_id}/customer_variants/${customerVariant.id}`,
            {
                data: {
                    lock_version: customerVariant.lock_version,
                },
                responseType: 'json',
            },
        )
    },
    async get(freight_type_id, customer_variant_id) {
        const response = await axios.get(
            `/api/freight_types/${freight_type_id}/customer_variants/${customer_variant_id}`,
            { responseType: 'json' },
        )
        return response.data.data
    },
    async list(id) {
        const response = await axios.get(
            `/api/freight_types/${id}/customer_variants`,
            { responseType: 'json' },
        )
        return response.data.data
    },
    async store(data) {
        const request_data = cloneDeep(data)
        request_data.trip_types = transformTripTypes(request_data.trip_types)
        const response = await axios.post(
            `/api/freight_types/${request_data.freight_type_id}/customer_variants`,
            request_data,
            { responseType: 'json' },
        )
        return response.data
    },
    async update(data) {
        const request_data = cloneDeep(data)
        request_data.trip_types = transformTripTypes(request_data.trip_types)
        const response = await axios.patch(
            `/api/freight_types/${request_data.freight_type_id}/customer_variants/${request_data.id}`,
            request_data,
            { responseType: 'json' },
        )
        return response.data.data
    },
}
export default CustomerVariant