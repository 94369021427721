/**
 * Part of Genesis application
 *
 * @copyright 2021 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

export const Price = {
    async list(customerVariant, params = undefined) {
        const response = await axios.get(
            `/api/freight_types/${customerVariant.freight_type_id}/customer_variants/${customerVariant.id}/prices`,
            { params, responseType: 'json' }
        )

        return params && params.freight_id ? response.data : response.data.data
    },
    async store(customerVariant, price_data) {
        const response = await axios.post(
            `/api/freight_types/${customerVariant.freight_type_id}/customer_variants/${customerVariant.id}/prices`,
            price_data,
            { responseType: 'json' },
        )

        return response.data
    },
    async update(customerVariant, price) {
        const response = await axios.patch(
            `/api/freight_types/${customerVariant.freight_type_id}/customer_variants/${customerVariant.id}/prices/${price.id}`,
            price,
            { responseType: 'json' },
        )

        return response.data.data
    },
    async destroy(customerVariant, price) {
        await axios.delete(
            `/api/freight_types/${customerVariant.freight_type_id}/customer_variants/${customerVariant.id}/prices/${price.id}`,
            {
                responseType: 'json',
                data: { lock_version: price.lock_version },
            },
        )
    }
}

export default Price