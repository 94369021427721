<template>
    <form>
        <genesis-textarea
            ref="remarks"
            v-model.trim="remarks"
            :label="$t('freight.freightDetail.remarks')"
            :maxlength="Laravel.EdiRemark.MAX_LENGTH_REMARK * Laravel.EdiRemark.MAX_ITEMS_REMARKS"
            @input="onInput"
        />
        <p>{{ $t('loadTender.loadTenderResponse.charactersNotAllowed') }}</p>
    </form>
</template>
<script>
import GenesisTextarea from '../../../components/GenesisTextarea.vue'

export default {
    name: 'ResponseLoadTenderForm',
    components: {GenesisTextarea},
    props: {
        value: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            remarks: '',
        }
    },
    model: {
        prop: 'value',
        event: 'input',
    },
    computed: {
        Laravel() {
            return Laravel
        },
    },
    created() {
        this.remarks = this.value
    },
    methods: {
        onInput(value) {
            this.remarks = value.trim().replace(/[~>*^]/g, '').replace(/\s+/g, ' ')
            this.$emit('input', this.remarks)
        },
    },
}
</script>