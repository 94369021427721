/**
 * Part of Genesis application
 *
 * @copyright 2019 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import defaultLocaleMessages from '../lang/es'
import 'vue2-datepicker/locale/es'

const defaultLocale = 'es'

const currentLocale = document.querySelector("html").getAttribute('lang')
let messages = {}
messages[defaultLocale] = defaultLocaleMessages

Vue.use(VueI18n)

export const i18n = new VueI18n({
    locale: defaultLocale,
    fallbackLocale: defaultLocale,
    messages
})

const loadedLocales = [defaultLocale]

function setI18nLocale (locale) {
    i18n.locale = locale
    document.querySelector('html').setAttribute('lang', locale)
    return locale
}

export function loadLocaleAsync (locale) {
    if (locale === null || locale === undefined) {
        return Promise.resolve(i18n.locale)
    }
    if (i18n.locale !== locale) {
        import(`vue2-datepicker/locale/${locale}`)

        if (!loadedLocales.includes(locale)) {
            return import(`../lang/${locale}`).then(module => {
                i18n.setLocaleMessage(locale, module.default)
                loadedLocales.push(locale)
                return setI18nLocale(locale)
            })
        }
        return Promise.resolve(setI18nLocale(locale))
    }
    return Promise.resolve(locale)
}

loadLocaleAsync(currentLocale)
