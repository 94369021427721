/**
 * Part of Genesis application
 *
 * @copyright 2023 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

export const Customer = {
    async bankAccounts(customer_group_id, customer_id, term) {
        const response = await axios.get(`/api/customer_groups/${customer_group_id}/customers/${customer_id}/bank_accounts`, {
            params: {term},
            responseType: 'json',
        })

        return response.data.data
    },
}

export default Customer