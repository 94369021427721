/**
 * Part of Genesis application
 *
 * @copyright 2022 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import {assignIn} from 'lodash'

class QueryStatusResponse {
    code
    isSuccess
    message
    fiscalVoucherStatus

    constructor(data) {
        assignIn(this, data)
    }

    isNotCancellable() {
        return this.fiscalVoucherStatus.isCancellable === 'No cancelable'
    }

    fiscalVoucherNotFound() {
        return this.code === 'N - 602'
    }
}

export default QueryStatusResponse