/**
 * Part of Genesis application
 *
 * @copyright 2021 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

async function fetchUnreadNotifications(user_id) {
    const response = await axios({
        method: 'GET',
        url: `/api/users/${user_id}/notifications`,
        responseType: 'json',
        params: {
            status: 'unread',
        },
    })
    return response.data && Array.isArray(response.data.data) ? response.data.data : []
}

async function notificationViewModel(user_id) {
    const response = await axios({
        method: 'GET',
        url: `/api/users/${user_id}/notifications/view_model`,
        responseType: 'json',
    })
    return response.data
}

export default {
    fetchUnreadNotifications,
    notificationViewModel,
    async readNotification(user_id, notification_id) {
        await axios.patch(`/api/users/${user_id}/notifications/${notification_id}/read`)
    },
}