/**
 * Part of Genesis application
 *
 * @copyright 2022 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

export const JournalAccount = {
    async list(params) {
        const response = await axios.get('/api/journal_accounts', {responseType: 'json', params})
        return response.data.data
    },
    async store(data) {
        const response = await axios.post('/api/journal_accounts', data, {responseType: 'json'})
        return response.data
    },
    async update(data) {
        const response = await axios.patch(`/api/journal_accounts/${data.id}`, data, {responseType: 'json'})
        return response.data.data
    },
}

export default JournalAccount