<template>
    <td>
        <a
            v-if="value"
            ref="status_update"
            class="is-link is-underlined"
            :title="value"
            @click="onButtonClick"
        >
            {{ value }}
        </a>
    </td>
</template>
<script>

import {mapGetters} from 'vuex'

export default {
    name: 'FreightStatusUpdateCell',
    props: {
        dataItem: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters({getCatalog: 'CatalogModule/getCatalog'}),
        shipmentStatusCodes() {
            return this.getCatalog(Laravel.CatalogType.ShipmentStatusCode)
        },
        value() {
            let value = this.dataItem.freight_status_update_code
            if (! value && this.dataItem.shipment_status_code) {
                const name = this.shipmentStatusCodes.find(o => o.id === this.dataItem.shipment_status_code).name
                value = `${this.dataItem.shipment_status_code} - ${name}`
            }

            return value
        },
    },
    methods: {
        onButtonClick() {
            this.$router.push({
                name: 'freights.show',
                params: {
                    id: this.dataItem.freight_id,
                },
                query: {
                    load_tender_id: this.dataItem.id,
                    tab: 'freight_status_update',
                },
            })
        },
    },
}
</script>
