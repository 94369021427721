/**
 * Part of Genesis application
 *
 * @copyright 2024 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

export const CoordinatedRoute = {
    async list(params) {
        const response = await axios.get('/api/coordinated_routes', {params})

        return response.data.data
    },
    async show(coordinated_route_id) {
        const response = await axios.get(`/api/coordinated_routes/${coordinated_route_id}`)

        return response.data
    },
    async store(coordinatedRoute) {
        const response = await axios.post(`/api/coordinated_routes`, coordinatedRoute)

        return response.data
    },
    async update(coordinatedRoute) {
        const response = await axios.patch(`/api/coordinated_routes/${coordinatedRoute.id}`, coordinatedRoute)

        return response.data.data
    },
}

export default CoordinatedRoute