<template>
    <kendo-grid
        ref="grid"
        :columns="gridColumns"
        :data-items="items"
        :filterable="true"
        v-bind:style="styleObject"
        @scroll="scrollHandler"
    >
        <grid-no-records>
            {{ is_loading && items.length === 0 ? $t('loading') : $t('noResultsFound') }}
        </grid-no-records>
        <template #actions-cell="{ props }">
            <actions-cell
                :data-item="props.dataItem"
                :actions="[
                    {
                        action: 'accept',
                        titleKey: 'loadTenders.grid.actions.accept',
                        iconClasses: [
                            'fas',
                            'fa-check',
                        ],
                        isVisible: (dataItem) => dataItem.status === Laravel.LoadTenderStatus.Received,
                    },
                    {
                        action: 'reject',
                        titleKey: 'loadTenders.grid.actions.reject',
                        iconClasses: [
                            'fas',
                            'fa-times',
                        ],
                        isVisible: (dataItem) => dataItem.status === Laravel.LoadTenderStatus.Received,
                    },
                    {
                        action: 'view',
                        titleKey: 'loadTenders.grid.actions.view',
                        iconClasses: [
                            'fas',
                            'fa-eye',
                        ],
                        isVisible: () => true,
                    },
                ]"
                :actions-callback="(action) => {
                    $emit('action-dispatched', { action, dataItem:props.dataItem })
                }"
            />
        </template>
        <template #freightCell="{ props }">
            <freight-cell
                :data-item="props.dataItem"
                @click="() => onFreightCellClick(props.dataItem)"
            />
        </template>
        <template #freightStatusUpdateCell="{ props }">
            <freight-status-update-cell :data-item="props.dataItem"/>
        </template>
        <template #selectFilter="{props}">
            <multi-select-filter
                :options="selectFilterOptions(props.field)"
                :field="props.field"
                @onChange="(newFilter) => onSelectFilterChange(newFilter, props.field)"
            />
        </template>
        <template #textFilter="{props}">
            <text-filter
                :field="props.field"
                @input="(newFilter) => onSelectFilterChange(newFilter, props.field)"
            />
        </template>
        <template #dateRangeFilter="{props}">
            <date-range-filter
                :field="props.field"
                @input="(newFilter) => onSelectFilterChange(newFilter, props.field)"
            />
        </template>
    </kendo-grid>
</template>
<script>
import {Grid, GridNoRecords} from '@progress/kendo-vue-grid'
import {getKendoGridFilter, transformKendoFilterToGenesisFilter} from '../../../components/kendo-grid/Utils'
import {mapGetters} from 'vuex'
import ActionsCell from '../../../components/kendo-grid/ActionsCell'
import FreightCell from './FreightCell'
import FreightStatusUpdateCell from './FreightStatusUpdateCell'
import Genesis from '../../../services/genesis'
import moment from 'moment'
import MultiSelectFilter from '../../../components/kendo-grid/MultiSelectFilter'
import TextFilter from '../../../components/kendo-grid/TextFilter'

export default {
    name: 'Grid',
    components: {
        ActionsCell,
        GridNoRecords,
        FreightCell,
        FreightStatusUpdateCell,
        'kendo-grid': Grid,
        MultiSelectFilter,
        TextFilter,
    },
    data() {
        return {
            filter: {},
            filterModel: {},
            gridColumns: [],
            is_loading: false,
            items: [],
            skip: 0,
            take: 100,
            styleObject: {
                height: (window.innerHeight - 140) + 'px',
            },
        }
    },
    watch: {
        '$i18n.locale': function () {
            this.gridColumns.forEach((column) => {
                column.title = this.$t(`loadTenders.grid.columns.${column.field}`)
            })
        },
    },
    computed: {
        ...mapGetters({
            getCatalog: 'CatalogModule/getCatalog',
        }),
        customerGroups() {
            return this.getCatalog(Laravel.CatalogType.CustomerGroup)
        },
        freightStatus() {
            return this.getCatalog(Laravel.CatalogType.FreightStatus)
        },
        Laravel() {
            return Laravel
        },
        loadTenderStatus() {
            return this.getCatalog(Laravel.CatalogType.LoadTenderStatus)
        },
        purposeCodes() {
            return this.getCatalog(Laravel.CatalogType.EdiTransactionSetPurposeCode)
        },
        shipmentStatusCodes() {
            return this.getCatalog(Laravel.CatalogType.ShipmentStatusCode)
        },
    },
    async created() {
        window.addEventListener('resize', () => {
            this.styleObject.height = (window.innerHeight - 110) + 'px'
        })
        const vm = this
        this.gridColumns = [
            {
                field: 'customer_groups',
                title: this.$t('loadTenders.grid.columns.customer'),
                cell: (h, tdElement, props) => {
                    return h(
                        'td',
                        tdElement.data,
                        vm.customerGroups.find(c => c.id === props.dataItem.customer_group_id).name,
                    )
                },
                filterCell: 'selectFilter',
            },
            {
                field: 'shipment_id',
                title: this.$t('loadTenders.grid.columns.shipment_id'),
                filterCell: 'textFilter',
            },
            {
                field: 'freight_id',
                title: this.$t('loadTenders.grid.columns.freight_id'),
                filterCell: 'textFilter',
                cell: 'freightCell',
            },
            {
                field: 'freight_status',
                title: this.$t('loadTenders.grid.columns.freight_status'),
                cell: (h, tdElement, props) => {
                    return h(
                        'td',
                        tdElement.data,
                        vm.freightStatus.find(c => c.id === props.dataItem.freight_status)?.name,
                    )
                },
                filterCell: 'selectFilter',
            },
            {
                field: 'vehicle_name',
                title: this.$t('loadTenders.grid.columns.vehicle_name'),
                filterCell: 'textFilter',
            },
            {
                field: 'trailer_name',
                title: this.$t('loadTenders.grid.columns.trailer_name'),
                filterCell: 'textFilter',
            },
            {
                field: 'status_update',
                title: this.$t('loadTenders.grid.columns.status_update'),
                filterable: false,
                cell: 'freightStatusUpdateCell',
            },
            {
                field: 'purpose_code',
                title: this.$t('loadTenders.grid.columns.purpose_code'),
                cell: (h, tdElement, props) => {
                    return h(
                        'td',
                        tdElement.data,
                        vm.purposeCodes.find(c => c.id === props.dataItem.purpose_code).name,
                    )
                },
                filterCell: 'selectFilter',
            },
            {
                field: 'service_date',
                title: this.$t('loadTenders.grid.columns.service_date'),
                cell: (h, tdElement, props) => {
                    return h(
                        'td',
                        tdElement.data,
                        moment(props.dataItem.service_date).format('YYYY-MM-DD HH:mm'),
                    )
                },
                type: 'date',
                filterable: false,
                width: '125',
            },
            {
                field: 'status',
                title: this.$t('loadTenders.grid.columns.status'),
                cell: (h, tdElement, props) => {
                    return h(
                        'td',
                        tdElement.data,
                        vm.loadTenderStatus.find(c => c.id === props.dataItem.status).name,
                    )
                },
                filterCell: 'selectFilter',
            },
            {
                field: 'origin',
                filterable: false,
                title: this.$t('loadTenders.grid.columns.origin'),
            },
            {
                field: 'origin_date_start',
                filterable: false,
                title: this.$t('loadTenders.grid.columns.origin_date_start'),
                cell: (h, tdElement, props) => {
                    return h(
                        'td',
                        tdElement.data,
                        moment(props.dataItem.origin_date_start).format('YYYY-MM-DD HH:mm'),
                    )
                },
                type: 'date',
            },
            {
                field: 'origin_date_end',
                filterable: false,
                title: this.$t('loadTenders.grid.columns.origin_date_end'),
                cell: (h, tdElement, props) => {
                    return h(
                        'td',
                        tdElement.data,
                        moment(props.dataItem.origin_date_end).format('YYYY-MM-DD HH:mm'),
                    )
                },
                type: 'date',
            },
            {
                field: 'stop_offs',
                filterable: false,
                title: this.$t('loadTenders.grid.columns.stop_offs'),
            },
            {
                field: 'destination',
                filterable: false,
                title: this.$t('loadTenders.grid.columns.destination'),
            },
            {
                field: 'destination_date_start',
                filterable: false,
                title: this.$t('loadTenders.grid.columns.destination_date_start'),
                cell: (h, tdElement, props) => {
                    return h(
                        'td',
                        tdElement.data,
                        moment(props.dataItem.destination_date_start).format('YYYY-MM-DD HH:mm'),
                    )
                },
                type: 'date',
            },
            {
                field: 'destination_date_end',
                filterable: false,
                title: this.$t('loadTenders.grid.columns.destination_date_end'),
                cell: (h, tdElement, props) => {
                    return h(
                        'td',
                        tdElement.data,
                        moment(props.dataItem.destination_date_end).format('YYYY-MM-DD HH:mm'),
                    )
                },
                type: 'date',
            },
            {
                field: 'actions',
                title: this.$t('loadTenders.grid.columns.actions'),
                cell: 'actions-cell',
                filterable: false,
            },
        ]
        await this.refreshItems()
    },
    methods: {
        onFreightCellClick(dataItem) {
            this.$emit('action-dispatched', {action: 'showFreight', dataItem})
        },
        onGridFilterChange(event) {
            this.filter = event.filter
            this.skip = 0
            this.items = []
            this.filterModel = transformKendoFilterToGenesisFilter(this.filter)
            this.refreshItems()
        },
        onSelectFilterChange(newFilter, field) {
            this.onGridFilterChange({
                filter: getKendoGridFilter(this.filter, newFilter, field),
            })
        },
        async refreshItems(reset_items = false) {
            if (reset_items) {
                this.skip = 0
                this.items = []
            }

            const items = await getItems.call(this)
            if (items.length > 0) {
                this.items = this.items.concat(items)
                this.skip = this.items.length
            }
        },
        async scrollHandler(event) {
            if (event.target.scrollTop + this.take >= event.target.scrollHeight - event.target.clientHeight) {
                await this.refreshItems()
            }
        },
        selectFilterOptions(field) {
            switch (field) {
                case 'customer_groups':
                    return this.customerGroups.filter(o => o.edi_enabled)
                case 'freight_status':
                    return this.freightStatus
                case 'purpose_code':
                    return this.purposeCodes
                case 'status':
                    return this.loadTenderStatus
                default:
                    throw new Error(`Field not implemented: ${field}`)
            }
        },
    },
}

async function getItems() {
    if (this.is_loading) {
        return []
    }

    this.is_loading = true

    let items = []
    try {
        items = await Genesis.traffic.loadTender.list({
            startRow: this.skip,
            endRow: this.skip + this.take,
            filterModel: this.filterModel,
        })
    } catch (error) {
        await this.$store.dispatch('handleAjaxError', {
            error,
            message_key: 'loadTenders.list.error',
        })
    } finally {
        this.is_loading = false
    }

    return items
}
</script>