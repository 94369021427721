/**
 * Part of Genesis application
 *
 * @copyright 2024 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

export const CatalogFile = {
    async downloadTemplate(params) {
        return await axios.get(`/api/catalog_files/download_template`, {params, responseType: 'blob'})
    },
    async store(data) {
        const formData = new FormData()
        formData.set('file_type_id', data.file_type_id)
        formData.set('file', data.file.getRawFile(), data.file.name)
        const response = await axios.post('/api/catalog_files', formData, {
            responseType: 'json',
            headers: {'Content-Type': 'multipart/form-data'},
        })

        return response.data
    },
}

export default CatalogFile