<template>
    <b-dropdown
        aria-role="menu"
        position="is-bottom-left"
        append-to-body
    >
        <template #trigger>
            <a>
                <span class="icon"><i class="fas fa-ellipsis-v"></i></span>
            </a>
        </template>
        <b-dropdown-item
            aria-role="menuitem"
            v-for="action in visibleActions"
            :key="action.action"
            :ref="action.action"
            @click="$emit('click', action.action)"
        >
            <span class="icon"><i :class="action.iconClasses"/></span>
            <span>{{ $t(action.titleKey) }}</span>
        </b-dropdown-item>
    </b-dropdown>
</template>

<script>

export default {
    name: 'DropdownAction',
    props: {
        actions: {
            type: Array,
            required: true,
        },
        params: {
            type: Object,
            required: true,
        },
    },
    computed: {
        visibleActions() {
            return this.actions.filter(action => action.isVisible(this.params))
        },
    },
}
</script>