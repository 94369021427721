/**
 * Part of Genesis application
 *
 * @copyright 2021 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

export const Freight = {
    async list(id, start_date, end_date) {
        const response = await axios.get(
            `/api/invoices/${id}/freights`,
            {
                responseType: 'json',
                params: {
                    start_date,
                    end_date,
                },
            })
        return response.data.data
    },
}

export default Freight