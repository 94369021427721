/**
 * Returns the filename for report download from response headers or default.
 *
 * @param  {Object}  headers
 * @param  {String}  defValue
 *
 * @return {String}
 */
export function getFilenameFromHeaders(headers, defValue) {
    const contentDisposition = headers['content-disposition']
    if (! contentDisposition) {
        return defValue
    }

    const filenameIndex = contentDisposition.indexOf('filename=')
    return filenameIndex >= 0 ? contentDisposition.substring(filenameIndex + 9) : defValue
}

/**
 * Creates HTML anchor element from response data blob.
 *
 * @param  {Object}  response
 *
 * @return {Object}
 */
export function createBlobAnchor(response) {
    const headers = response.headers
    const blob = new Blob([response.data], {type: headers['content-type']})
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    return link
}

export default {
    getFilenameFromHeaders,
    createBlobAnchor,
}