<template>
    <div
        :id="modalId"
        class="modal"
        @keyup.esc="onCloseClick"
    >
        <div
            class="modal-background"
            @click="onCloseClick"
        />
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">
                    {{ title }}
                </p>
                <button
                    class="delete"
                    :aria-label="$t('close')"
                    @click="onCloseClick"
                />
            </header>
            <section class="modal-card-body">
                <p>{{ message }}</p>
                <slot name="content" />
            </section>
            <footer class="modal-card-foot">
                <button
                    v-t="'Yes'"
                    :class="['button', 'is-success', { 'is-loading': isSaving }]"
                    :disabled="confirmButtonDisabled"
                    @click.prevent="onConfirmClick"
                />
                <button
                    v-t="'No'"
                    class="button"
                    @click.prevent="onCloseClick"
                />
            </footer>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    props: {
        modalId: {
            type: String,
            required: true,
        },
        confirmButtonDisabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            isSaving: false,
        }
    },
    computed: {
        ...mapGetters(['getModalParams']),
        params() {
            return this.getModalParams(this.modalId)
        },
        title() {
            return this.params ? this.params.title : ''
        },
        message() {
            return this.params ? this.params.message : ''
        },
    },
    methods: {
        ...mapActions({closeConfirmationModal: 'closeModal'}),
        ...mapMutations(['clearModalParams']),
        onConfirmClick() {
            this.isSaving = true
            this.$emit('confirm', this.params)
            this.closeModal()
        },
        onCloseClick() {
            this.closeModal()
            this.$emit('close', this.params)
        },
        closeModal() {
            this.reset()
            this.closeConfirmationModal(this.modalId)
        },
        reset() {
            this.isSaving = false
            this.clearModalParams(this.modalId)
        },
    },
}
</script>
