/**
 * Part of Genesis application
 *
 * @copyright 2021 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

export const Locality = {
    async list(customer_group_id, term = '', filter_by_customer = false) {
        const response = await axios.get(`/api/customer_groups/${customer_group_id}/freight_localities/search`, {
            params: {
                term: term ?? '',
                rt: filter_by_customer ? 1 : 0,
            },
            responseType: 'json',
        })
        return response.data.data
    },
}

export default Locality