/**
 * Part of Genesis application
 *
 * @copyright 2022 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

export const RelatedFiscalVoucher = {
    async list(id) {
        const response = await axios.get(`/api/invoicing_vouchers/${id}/related_vouchers`)
        return response.data
    },
}

export default RelatedFiscalVoucher
