/**
 * Part of Genesis application
 *
 * @copyright 2022 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

export const InvoicingVoucher = {
    async list(credit_note_id) {
        const response = await axios.get(`/api/credit_notes/${credit_note_id}/invoicing_vouchers`, {responseType: 'json'})

        return response.data.data
    },
}

export default InvoicingVoucher