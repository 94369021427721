<script>
import UserAuthorization from '../UserAuthorization'
import FreightTripStatus from '../FreightTripStatus'

export default {
    methods: {
        $can: UserAuthorization.can,
        $freightMovementStatusAllowsUpdate(status) {
            return (new FreightTripStatus(status)).allowsUpdate()
        },
    },
}
</script>
