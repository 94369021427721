/**
 * Part of Genesis application
 *
 * @copyright 2023 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

export const Attachment = {
    async delete(entity_type, entity_id, attachment_type, data = {}) {
        await axios.delete(`/api/${entity_type}/${entity_id}/attachments/${attachment_type}`, {data})
    },
    async show(entity_type, entity_id, attachment_type, params = {}) {
        return await axios.get(
            `/api/${entity_type}/${entity_id}/attachments/${attachment_type}`,
            {params, responseType: 'blob'},
        )
    },
    async store(data) {
        const formData = new FormData()
        formData.set('attachment_type_id', data.attachment_type_id)
        formData.set('file', data.file.getRawFile(), data.file.name)
        formData.set('entity_id', data.entity_id)
        formData.set('entity_type', data.entity_type)

        const response = await axios.post(`/api/attachments`, formData, {
            responseType: 'json',
            headers: {'Content-Type': 'multipart/form-data'},
        })

        return response.data
    },
}

export default Attachment