/**
 * Part of Genesis application
 *
 * @copyright 2021 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

export function decimalFormat(value, precision = 2) {
    const numericValue = Number(value)
    if (isNaN(numericValue)) {
        return ''
    }

    const numberFormatter = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        maximumFractionDigits: precision,
        minimumFractionDigits: precision,
    })
    return numberFormatter.format(numericValue)
}

export default decimalFormat