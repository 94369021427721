/**
 * Part of Genesis application
 *
 * @copyright 2022 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import assignIn from 'lodash/assignIn'
import cloneDeep from 'lodash/cloneDeep'
import Genesis from '../services/genesis'

const state = {
    catalogs: {},
}

export const actions = {
    async loadCatalogs(context, catalog_types) {
        return new Promise(async (resolve, reject) => {
            for (const catalog_type of catalog_types) {
                try {
                    await context.dispatch('loadCatalog', catalog_type)
                } catch (e) {
                    reject(e)
                    return
                }
            }

            resolve()
        })
    },
    async loadCatalog({state, getters, commit}, catalog_type) {
        if (! getters.isLoaded(catalog_type)) {
            const catalogData = await Genesis.catalog.get(catalog_type)

            const newData = assignIn({}, cloneDeep(state.catalogs), {[catalog_type]: catalogData})

            commit('setCatalogs', newData)
        }
    },
}

export const mutations = {
    setCatalogs: (state, catalogs) => state.catalogs = catalogs,
}

export const getters = {
    getCatalog: state => catalog_type => state.catalogs[catalog_type],
    isLoaded: state => catalog_type => Object.prototype.hasOwnProperty.call(state.catalogs, catalog_type),
}

export const CatalogModule = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
}