<template>
    <div class="navbar-item has-dropdown is-hoverable">
        <a
            href="#"
            class="navbar-link pt-0 pb-0"
        >
            <div class="button is-primary">
                <span class="icon">
                    <span
                        v-if="pendingNotifications > 0"
                        class="badge is-danger is-right"
                    >{{ pendingNotifications }}</span>
                    <i class="fas fa-bell" />
                </span>
            </div>
        </a>
        <div class="navbar-dropdown is-right">
            <a
                ref="pendingLoadTenders"
                class="navbar-item"
                @click="onPendingLoadTendersClick"
            >
                <span class="mr-4">{{ $t('navBar.loadTenders') }}</span>
                <span class="tag is-light">{{ pendingLoadTenders }}</span>
            </a>
        </div>
    </div>
</template>
<script>
export default {
    name: 'NotificationDropDown',
    props: {
        value: {
            type: Array,
            required: true,
        },
    },
    computed: {
        pendingLoadTenders() {
            return this.value.filter(n => n.type === 'App\\Notifications\\LoadTenderReceived').length
        },
        pendingNotifications() {
            return this.value.length
        },
    },
    methods: {
        onPendingLoadTendersClick() {
            const type = 'App\\Notifications\\LoadTenderReceived'
            this.$emit('show-notifications', {
                type,
                notifications: this.value.filter(n => n.type === type),
            })
        },
    },
}
</script>