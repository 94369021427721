/**
 * Part of Genesis application
 *
 * @copyright 2021 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'
import fiscalVoucherAddenda from './FiscalVoucherAddenda'
import item from './Item'
import stampError from './StampError'

export const Invoice = {
    fiscalVoucherAddenda,
    item,
    stampError,
    async import(data) {
        const formData = new FormData()
        formData.append('external_system_id', data.external_system_id)
        formData.append('file', data.file, data.file.name)
        const response = await axios.post('/invoices/import', formData, {
            responseType: 'json',
            headers: {'Content-Type': 'multipart/form-data'},
        })
        return response.data
    },
    async importData(data) {
        const response = await axios.post('/invoices/import_data', data)
        return response.data
    },
    async nullify(invoice_id) {
        await axios.patch(`/api/invoices/${invoice_id}/nullify`, null, {responseType: 'json'})
    },
    async stamp(invoice_id) {
        await axios.patch(`/api/invoices/${invoice_id}/stamp`, null, {responseType: 'json'})
    },
    async store(data) {
        const response = await axios.post(`/invoices`, data)
        return response.data
    },
    async viewModel() {
        const response = await axios.get('/api/invoices/view_model', {responseType: 'json'})
        return response.data
    },
}

export default Invoice