/**
 * Part of Genesis application
 *
 * @copyright 2023 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

export const FreightStatusUpdate = {
    async download(freight_id) {
        return await axios.get(`/api/freights/${freight_id}/freight_status_updates/download`, {responseType: 'blob'})
    },
    async list(freight_id) {
        const response = await axios.get(`/api/freights/${freight_id}/freight_status_updates`, {responseType: 'json'})

        return response.data.data
    },
}

export default FreightStatusUpdate