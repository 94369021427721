<template>
    <td>
        <a
            v-if="dataItem.freight_id"
            ref="freight_id"
            class="button is-text"
            @click="() => $emit('click')"
        >
            {{ dataItem.freight_id }}
        </a>
        <span
            v-else
            ref="freight_id"
        >{{ dataItem.freight_id }}</span>
    </td>
</template>
<script>

export default {
    name: 'FreightCell',
    props: {
        dataItem: {
            type: Object,
            required: true,
        },
    },
}
</script>
