/**
 * Part of Genesis application
 *
 * @copyright 2020 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

const state = {
    breadcrumbs: [],
    title: null,
}

export const mutations = {
    setBreadcrumbs(state, breadcrumbs) {
        if (Array.isArray(breadcrumbs)) {
            state.breadcrumbs = breadcrumbs
        }
    },
    setTitle(state, title) {
        state.title = title
    },
}

export const actions = {
    setBreadcrumbsAndTitle({ commit }, { breadcrumbs, title }) {
        commit('setBreadcrumbs', breadcrumbs)
        commit('setTitle', title)
    },
}

export const BreadcrumbModule = {
    namespaced: true,
    state,
    mutations,
    actions,
}
