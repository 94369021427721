<template>
    <nav
        :aria-label="$t('navBar.mainNavigation')"
        class="navbar is-fixed-top is-primary"
        role="navigation"
    >
        <div class="navbar-brand">
            <a
                v-if="guest"
                class="navbar-item"
                href="#"
            >{{ appName }}</a>
            <router-link
                v-else
                :to="{ name: 'home'}"
                class="navbar-item"
            >
                {{ appName }}
            </router-link>
            <a
                :aria-label="$t('navBar.menu.label')"
                aria-expanded="false"
                class="navbar-burger burger"
                data-target="navMenu"
                role="button"
            >
                <span aria-hidden="true"/>
                <span aria-hidden="true"/>
                <span aria-hidden="true"/>
            </a>
        </div>
        <div
            id="navMenu"
            class="navbar-menu"
        >
            <div class="navbar-start">
                <div
                    v-if="! guest"
                    class="navbar-item has-dropdown is-hoverable"
                >
                    <a
                        class="navbar-link"
                        href="#"
                    >{{ $t('navBar.menu.traffic.label') }}</a>
                    <div class="navbar-dropdown">
                        <router-link
                            v-if="can(Laravel.Ability.VIEW_ANY, Laravel.Model.ServiceRequest)"
                            :to="{ name: 'service_request_files.index' }"
                            class="navbar-item"
                        >
                            {{ $t('navBar.menu.traffic.serviceRequestFiles') }}
                        </router-link>
                        <router-link
                            v-if="can(Laravel.Ability.VIEW_ANY, Laravel.Model.TrafficService)"
                            :to="{ name: 'freights.index' }"
                            class="navbar-item"
                        >
                            {{ $t('navBar.menu.traffic.freights') }}
                        </router-link>
                        <router-link
                            v-if="can(Laravel.Ability.VIEW_ANY, Laravel.Model.Shipment)"
                            :to="{ name: 'freight_movements' }"
                            class="navbar-item"
                        >
                            {{ $t('navBar.menu.traffic.freightMovements') }}
                        </router-link>
                        <router-link
                            v-if="can(Laravel.Ability.VIEW_ANY, Laravel.Model.LoadTender)"
                            :to="{ name: 'load_tenders.index' }"
                            class="navbar-item"
                        >
                            {{ $t('navBar.menu.traffic.loadTenders') }}
                        </router-link>
                    </div>
                </div>
                <div
                    v-if="! guest"
                    class="navbar-item has-dropdown is-hoverable"
                >
                    <a class="navbar-link" href="#">{{ $t('navBar.menu.billing.label') }}</a>
                    <div class="navbar-dropdown">
                        <router-link
                            v-if="can(Laravel.Ability.VIEW, Laravel.Model.Invoice)"
                            :to="{ name: 'billing_documents.index' }"
                            class="navbar-item"
                        >
                            {{ $t('navBar.menu.billing.billingDocuments') }}
                        </router-link>
                        <router-link
                            v-if="displayCoordinatedFiscalVoucher"
                            :to="{ name: 'coordinated_fiscal_vouchers.index' }"
                            class="navbar-item"
                        >
                            {{ $t('navBar.menu.billing.coordinatedFiscalVouchers') }}
                        </router-link>
                    </div>
                </div>
                <div
                    v-if="! guest"
                    class="navbar-item has-dropdown is-hoverable"
                >
                    <a class="navbar-link" href="#">{{ $t('navBar.menu.accounts_receivable.label') }}</a>
                    <div class="navbar-dropdown">
                        <router-link
                            v-if="can(Laravel.Ability.VIEW_ANY, Laravel.Model.AccountsReceivablePayment)"
                            :to="{ name: 'accounts_receivable.payments.index' }"
                            class="navbar-item"
                        >
                            {{ $t('navBar.menu.accounts_receivable.payments') }}
                        </router-link>
                    </div>
                </div>
                <div
                    v-if="! guest && displayCoordinatedLiquidation"
                    class="navbar-item has-dropdown is-hoverable"
                >
                    <a class="navbar-link" href="#">{{ $t('navBar.menu.accounts_payable.label') }}</a>
                    <div class="navbar-dropdown">
                        <router-link class="navbar-item" :to="{ name: 'coordinated_liquidations.index' }">
                            {{ $t('navBar.menu.accounts_payable.coordinatedLiquidations') }}
                        </router-link>
                    </div>
                </div>
                <div
                    v-if="displayAccountingMenu"
                    class="navbar-item has-dropdown is-hoverable"
                >
                    <a
                        class="navbar-link"
                        href="#"
                    >{{ $t('navBar.menu.accounting.label') }}</a>
                    <div class="navbar-dropdown">
                        <a
                            v-if="can(Laravel.Ability.VIEW, Laravel.Model.JournalVoucherExtract)"
                            class="navbar-item"
                            href="/journal_voucher_extracts"
                        >{{ $t('navBar.menu.accounting.journalVouchers') }}</a>
                    </div>
                </div>
                <div
                    v-if="! guest"
                    class="navbar-item has-dropdown is-hoverable"
                >
                    <a
                        class="navbar-link"
                        href="#"
                    >{{ $t('navBar.menu.catalogs.label') }}</a>
                    <div class="navbar-dropdown">
                        <div class="dropdown-nested dropdown-item control has-icons-right pr-6">
                            <div class="dropdown-trigger">
                                <a class="button is-white p-0" aria-haspopup="true"
                                   aria-controls="fiscalVoucher"
                                >
                                    {{ $t('navBar.menu.administration.label') }}
                                </a>
                                <span class="icon is-right"><font-awesome-icon :icon="['fas', 'angle-down']"/></span>
                            </div>
                            <div class="dropdown-menu" id="fiscalVoucher" role="menu">
                                <div class="dropdown-content">
                                    <router-link
                                        v-if="can(Laravel.Ability.VIEW, Laravel.Model.Issuer)"
                                        :to="{ name: 'issuers.show', params: {id: 1} }"
                                        class="navbar-item"
                                    >{{ $t('navBar.menu.catalogs.issuers') }}
                                    </router-link>
                                    <a
                                        v-if="can(Laravel.Ability.VIEW, Laravel.Model.Role)"
                                        class="navbar-item"
                                        href="/roles"
                                    >{{ $t('navBar.menu.catalogs.roles') }}</a>
                                    <a
                                        v-if="can(Laravel.Ability.VIEW, Laravel.Model.User)"
                                        class="navbar-item"
                                        href="/users"
                                    >{{ $t('navBar.menu.catalogs.users') }}</a>
                                </div>
                            </div>
                        </div>
                        <hr class="dropdown-divider m-0">
                        <div class="dropdown-nested dropdown-item control has-icons-right pr-6">
                            <div class="dropdown-trigger">
                                <a class="button is-white p-0" aria-haspopup="true"
                                   aria-controls="fiscalVoucher"
                                >
                                    {{ $t('navBar.menu.fiscalVoucher.label') }}
                                </a>
                                <span class="icon is-right"><font-awesome-icon :icon="['fas', 'angle-down']"/></span>
                            </div>
                            <div class="dropdown-menu" id="fiscalVoucher" role="menu">
                                <div class="dropdown-content">
                                    <a
                                        v-if="can(Laravel.Ability.VIEW, Laravel.Model.InvoicingCustomerGroup)"
                                        class="navbar-item"
                                        href="/invoicing_customer_groups"
                                    >{{ $t('navBar.menu.catalogs.customers') }}</a>
                                    <a
                                        v-if="can(Laravel.Ability.VIEW, Laravel.Model.InvoicingItemType)"
                                        class="navbar-item"
                                        href="/invoicing_item_types"
                                    >{{ $t('navBar.menu.catalogs.invoicingItemTypes') }}</a>
                                    <router-link
                                        v-if="can(Laravel.Ability.VIEW_ANY, Laravel.Model.FreightType)"
                                        :to="{ name: 'customer_variants.index' }"
                                        class="navbar-item"
                                    >
                                        {{ $t('navBar.menu.catalogs.customerVariants') }}
                                    </router-link>
                                    <a
                                        v-if="can(Laravel.Ability.VIEW, Laravel.Model.BankAccount)"
                                        class="navbar-item"
                                        href="/bank_accounts"
                                    >{{ $t('navBar.menu.catalogs.bankAccounts') }}</a>
                                    <router-link
                                        v-if="can(Laravel.Ability.VIEW_ANY, Laravel.Model.JournalAccount)"
                                        :to="{ name: 'journal_accounts.index' }"
                                        class="navbar-item"
                                    >
                                        {{ $t('navBar.menu.catalogs.journalAccounts') }}
                                    </router-link>
                                    <router-link
                                        v-if="displayCoordinatedRoute"
                                        :to="{ name: 'coordinated_routes.index' }"
                                        class="navbar-item"
                                    >
                                        {{ $t('navBar.menu.catalogs.coordinatedRoutes') }}
                                    </router-link>
                                    <a
                                        v-if="can(Laravel.Ability.VIEW, Laravel.Model.ExchangeRate)"
                                        class="navbar-item"
                                        href="/exchange_rates"
                                    >{{ $t('navBar.menu.catalogs.exchangeRates') }}</a>
                                    <router-link
                                        v-if="can(Laravel.Ability.VIEW_ANY, Laravel.Model.VoucherFolioSeries)"
                                        :to="{ name: 'voucher_folio_series.index' }"
                                        class="navbar-item"
                                    >{{ $t('navBar.menu.catalogs.voucherFolioSeries') }}
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <hr class="dropdown-divider m-0">
                        <div class="dropdown-nested dropdown-item control has-icons-right pr-6">
                            <div class="dropdown-trigger">
                                <a class="button is-white p-0" aria-haspopup="true"
                                   aria-controls="traffic"
                                >
                                    {{ $t('navBar.menu.traffic.label') }}
                                </a>
                                <span class="icon is-right"><font-awesome-icon :icon="['fas', 'angle-down']"/></span>
                            </div>
                            <div class="dropdown-menu" id="traffic" role="menu">
                                <div class="dropdown-content">
                                    <router-link
                                        v-if="can(Laravel.Ability.VIEW_ANY, Laravel.Model.FreightDriver)"
                                        :to="{ name: 'freight_drivers.index' }"
                                        class="navbar-item"
                                    >
                                        {{ $t('navBar.menu.catalogs.drivers') }}
                                    </router-link>
                                    <router-link
                                        v-if="can(Laravel.Ability.VIEW_ANY, Laravel.Model.TrafficLocality)"
                                        :to="{ name: 'localities.index' }"
                                        class="navbar-item"
                                    >
                                        {{ $t('navBar.menu.catalogs.localities') }}
                                    </router-link>
                                    <router-link
                                        v-if="can(Laravel.Ability.VIEW_ANY, Laravel.Model.VehiclePermit)"
                                        :to="{ name: 'vehicle_permits.index' }"
                                        class="navbar-item"
                                    >
                                        {{ $t('navBar.menu.catalogs.vehiclePermits') }}
                                    </router-link>
                                    <router-link
                                        v-if="can(Laravel.Ability.VIEW_ANY, Laravel.Model.FreightTemplate)"
                                        :to="{ name: 'freight_templates.index' }"
                                        class="navbar-item"
                                    >
                                        {{ $t('navBar.menu.catalogs.freightTemplates') }}
                                    </router-link>
                                    <router-link
                                        v-if="can(Laravel.Ability.VIEW_ANY, Laravel.Model.TrailerOwner)"
                                        :to="{ name: 'trailer_owners.index' }"
                                        class="navbar-item"
                                    >
                                        {{ $t('navBar.menu.catalogs.trailerOwners') }}
                                    </router-link>
                                    <router-link
                                        v-if="can(Laravel.Ability.VIEW_ANY, Laravel.Model.TrafficTrailer)"
                                        :to="{ name: 'freight_trailers.index' }"
                                        class="navbar-item"
                                    >
                                        {{ $t('navBar.menu.catalogs.trailers') }}
                                    </router-link>
                                    <router-link
                                        v-if="can(Laravel.Ability.VIEW_ANY, Laravel.Model.VehicleInsurance)"
                                        :to="{ name: 'vehicle_insurances.index' }"
                                        class="navbar-item"
                                    >
                                        {{ $t('navBar.menu.catalogs.vehicleInsurances') }}
                                    </router-link>
                                    <router-link
                                        v-if="can(Laravel.Ability.VIEW, Laravel.Model.FreightTripType)"
                                        :to="{ name: 'freight_trip_types.index' }"
                                        class="navbar-item"
                                    >
                                        {{ $t('navBar.menu.catalogs.freightTripTypes') }}
                                    </router-link>
                                    <router-link
                                        v-if="can(Laravel.Ability.VIEW_ANY, Laravel.Model.FreightType)"
                                        :to="{ name: 'freight_types.index' }"
                                        class="navbar-item"
                                    >
                                        {{ $t('navBar.menu.catalogs.freightTypes') }}
                                    </router-link>
                                    <router-link
                                        v-if="can(Laravel.Ability.VIEW_ANY, Laravel.Model.TrafficTractor)"
                                        :to="{ name: 'vehicles.index' }"
                                        class="navbar-item"
                                    >
                                        {{ $t('navBar.menu.catalogs.vehicles') }}
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="! guest"
                    class="navbar-item has-dropdown is-hoverable"
                >
                    <a
                        class="navbar-link"
                        href="#"
                    >{{ $t('navBar.menu.query.label') }}</a>
                    <div class="navbar-dropdown">
                        <router-link
                            v-if="can(Laravel.Ability.QUERY_INVOICE_BALANCE_AGING, Laravel.Model.Invoice)"
                            :to="{ name: 'queries.invoice_balance_aging' }"
                            class="navbar-item"
                        >
                            {{ $t('navBar.menu.query.invoiceBalanceAging') }}
                        </router-link>
                        <router-link
                            v-if="can(Laravel.Ability.QUERY_DAILY_BILLING, Laravel.Model.Shipment)"
                            :to="{ name: 'queries.daily_billing' }"
                            class="navbar-item"
                        >
                            {{ $t('navBar.menu.query.dailyBilling') }}
                        </router-link>
                        <router-link
                            v-if="can(Laravel.Ability.QUERY_ACCOUNT_STATEMENT, Laravel.Model.Invoice)"
                            :to="{ name: 'queries.account_statement' }"
                            class="navbar-item"
                        >
                            {{ $t('navBar.menu.query.accountStatement') }}
                        </router-link>
                        <router-link
                            v-if="displayQueryCustomerAccountStatement"
                            :to="{ name: 'queries.customer_account_statement' }"
                            class="navbar-item"
                        >
                            {{ $t('navBar.menu.query.customerAccountStatement') }}
                        </router-link>
                        <router-link
                            v-if="can(Laravel.Ability.QUERY_INVOICES_BY_VEHICLE, Laravel.Model.Shipment)"
                            :to="{ name: 'queries.invoices_by_vehicle' }"
                            class="navbar-item"
                        >
                            {{ $t('navBar.menu.query.invoicesByVehicle') }}
                        </router-link>
                        <router-link
                            v-if="can(Laravel.Ability.QUERY_INVOICES_RECEIVABLE, Laravel.Model.Invoice)"
                            :to="{ name: 'queries.invoices_receivable' }"
                            class="navbar-item"
                        >
                            {{ $t('navBar.menu.query.invoicesReceivable') }}
                        </router-link>
                        <router-link
                            v-if="can(Laravel.Ability.QUERY_TRIPS_PER_INVOICE, Laravel.Model.Shipment)"
                            :to="{ name: 'queries.freight_trips_per_invoice' }"
                            class="navbar-item"
                        >
                            {{ $t('navBar.menu.query.freightTripsPerInvoice') }}
                        </router-link>
                        <router-link
                            v-if="can(Laravel.Ability.QUERY_TRIPS_BY_VEHICLE, Laravel.Model.Shipment)"
                            :to="{ name: 'queries.freight_trips_by_vehicle' }"
                            class="navbar-item"
                        >
                            {{ $t('navBar.menu.query.freightTripsByVehicle') }}
                        </router-link>
                        <router-link
                            v-if="can(Laravel.Ability.QUERY_INVOICE_PAYMENTS, Laravel.Model.Payment)"
                            :to="{ name: 'queries.invoice_payments' }"
                            class="navbar-item"
                        >
                            {{ $t('navBar.menu.query.invoicePayments') }}
                        </router-link>
                    </div>
                </div>
                <router-link
                    v-if="! guest"
                    :to="{ name: 'reports' }"
                    class="navbar-item"
                >
                    {{ $t('navBar.menu.reports') }}
                </router-link>
            </div>
            <div class="navbar-end">
                <a
                    v-if="guest"
                    class="navbar-item"
                    href="/login"
                >{{ $t('navBar.logIn') }}</a>
                <notification-drop-down
                    v-if="! guest"
                    :value="notifications"
                    @show-notifications="onShowNotifications"
                />
                <div
                    v-if="! guest"
                    class="navbar-item has-dropdown is-hoverable"
                >
                    <a class="navbar-link">
                        <i class="far fa-headset"/>
                    </a>
                    <div class="navbar-dropdown is-right">
                        <a
                            ref="technicalSupport"
                            class="navbar-item"
                            @click="onFeedbackClick(Laravel.Genesis.TECHNICAL_SUPPORT_LABEL)"
                        >
                            <span class="icon"><i class="far fa-headset"/></span>
                            <span>{{ $t('navBar.feedback.technicalSupport') }}</span>
                        </a>
                    </div>
                </div>
                <div
                    v-if="! guest"
                    class="navbar-item has-dropdown is-hoverable"
                >
                    <a
                        class="navbar-link"
                        href="#"
                    >{{ Laravel.user.name }}</a>
                    <div
                        id="log-out-dropdown"
                        class="navbar-dropdown"
                    >
                        <a
                            class="navbar-item"
                            href="/logout"
                            @click.prevent="onLogoutClick"
                        >
                            {{ $t('navBar.logOut') }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>
<script>
import UserAuthorization from '../UserAuthorization'
import NotificationDropDown from './NotificationDropDown'
import axios from 'axios'

export default {
    name: 'NavBar',
    components: {
        NotificationDropDown,
    },
    props: {
        appName: {
            type: String,
            default: 'Génesis',
        },
        notifications: {
            type: Array,
            required: true,
        },
    },
    computed: {
        displayAccountingMenu() {
            return ! this.guest && Laravel.genesisConfiguration.journal_voucher.generation_enabled
        },
        displayCoordinatedFiscalVoucher() {
            return Laravel.genesisConfiguration.coordinated_regime.enabled &&
                UserAuthorization.can(Laravel.Ability.VIEW_ANY, Laravel.Model.CoordinatedFiscalVoucher)
        },
        displayCoordinatedLiquidation() {
            return Laravel.genesisConfiguration.coordinated_regime.enabled &&
                UserAuthorization.can(Laravel.Ability.VIEW_ANY, Laravel.Model.CoordinatedLiquidation)
        },
        displayCoordinatedRoute() {
            return Laravel.genesisConfiguration.coordinated_regime.enabled &&
                UserAuthorization.can(Laravel.Ability.VIEW_ANY, Laravel.Model.CoordinatedRoute)
        },
        displayQueryCustomerAccountStatement() {
            return Laravel.genesisConfiguration.query.customerAccountStatementEnabled &&
                UserAuthorization.can(Laravel.Ability.QUERY_CUSTOMER_ACCOUNT_STATEMENT, Laravel.Model.InvoicingVoucher)
        },
        guest() {
            return Laravel.user === null
        },
        Laravel() {
            return Laravel
        },
    },
    methods: {
        can(ability, model) {
            return UserAuthorization.can(ability, model)
        },
        onFeedbackClick(action) {
            this.$emit('action-dispatched', {action})
        },
        onShowNotifications(data) {
            this.$emit('action-dispatched', {action: 'notifications', data})
        },
        onLogoutClick(event) {
            const anchor = event.target
            axios.post(anchor.href)
                .finally(
                    () => document.location.assign('/'),
                )
        },
    },
}
</script>

<style scoped>
.dropdown-nested:hover > .dropdown-menu {
    display: block;
}

.dropdown-nested .dropdown-menu {
    top: -10px;
    margin-left: 100%;
}
</style>
