/**
 * Part of Genesis application
 *
 * @copyright 2022 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

export const FiscalVoucherCancellationRequest = {
    async list(fiscal_voucher_id) {
        const response = await axios
            .get(`/api/fiscal_vouchers/${fiscal_voucher_id}/fiscal_voucher_cancellation_requests`)

        return response.data.data
    },
}

export default FiscalVoucherCancellationRequest