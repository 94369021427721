<template>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">
                {{ $t('loadTender.loadTenderResponse.title') }}
            </p>
            <button
                class="delete"
                :title="$t('close')"
                aria-label="close"
                @click="onCloseButtonClick"
            />
        </header>
        <section class="modal-card-body">
            <p>{{ message }}</p>
            <response-load-tender-form v-model="remarks"/>
        </section>
        <footer class="modal-card-foot">
            <button
                ref="confirm"
                class="button is-success"
                :disabled="disableConfirmButton"
                @click="onConfirmButtonClick"
            >{{ $t('Confirm') }}
            </button>
            <button
                ref="close"
                class="button is-info"
                @click="onCloseButtonClick"
            >{{ $t('close') }}
            </button>
        </footer>
    </div>
</template>

<script>
import ResponseLoadTenderForm from './ResponseLoadTenderForm'

export default {
    name: 'ResponseLoadTenderModal',
    components: {ResponseLoadTenderForm},
    props: {
        loadTender: {
            type: Object,
        },
        reservationActionCode: {
            type: String,
        },
    },
    data() {
        return {
            remarks: '',
        }
    },
    computed: {
        disableConfirmButton() {
            return this.loadTender.freight_id &&
                this.loadTender.purpose_code === Laravel.PurposeCode.CANCELLATION &&
                this.reservationActionCode === Laravel.ReservationAction.RESERVATION_ACCEPTED &&
                ! this.remarks
        },
        message() {
            return this.$t(`loadTender.loadTenderResponse.${this.loadTender.purpose_code}.${this.reservationActionCode}`)
        },
    },
    methods: {
        onCloseButtonClick() {
            this.$emit('close')
        },
        onConfirmButtonClick() {
            this.$emit('confirm', {
                loadTender: this.loadTender,
                reservationActionCode: this.reservationActionCode,
                remarks: this.remarks,
            })
        },
    },
}
</script>