/**
 * Part of Genesis application
 *
 * @copyright 2024 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

export const CoordinatedLiquidation = {
    async coordinated_fiscal_vouchers(id) {
        const response = await axios.get(`/api/coordinated_liquidations/${id}/coordinated_fiscal_vouchers`)

        return response.data.data
    },
    async liquidate(coordinatedLiquidation) {
        await axios.patch(`/api/coordinated_liquidations/${coordinatedLiquidation.id}/liquidate`, coordinatedLiquidation)
    },
    async list(params) {
        const response = await axios.get('/api/coordinated_liquidations', {params})

        return response.data.data
    },
    async show(coordinated_liquidation_id) {
        const response = await axios.get(`/api/coordinated_liquidations/${coordinated_liquidation_id}`)

        return response.data
    },
    async store(data) {
        const response = await axios.post(`/api/coordinated_liquidations`, data)

        return response.data
    },
    async update(coordinatedLiquidation) {
        const response = await axios.patch(`/api/coordinated_liquidations/${coordinatedLiquidation.id}`, coordinatedLiquidation)

        return response.data
    },
}

export default CoordinatedLiquidation