/**
 * Part of Genesis application
 *
 * @copyright 2024 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import coordinatedFiscalVoucher from './coordinatedFiscalVoucher'
import coordinatedLiquidation from './coordinatedLiquidation'
import coordinatedRoute from './coordinatedRoute'
import coordinatedTrip from './coordinatedTrip'
import trailerOwnerCoordinatedTrip from './trailerOwner/coordinatedTrip'

export const Coordinated = {
    coordinatedFiscalVoucher,
    coordinatedLiquidation,
    coordinatedRoute,
    coordinatedTrip,
    trailerOwnerCoordinatedTrip,
}

export default Coordinated