<template>
    <window
        :title="$t('loadTender.window.title')"
        :initial-height="450"
        :initial-width="900"
        :draggable="true"
        :top="window_top"
        :left="window_left"
        :width="window_width"
        :height="window_height"
        @close="onWindowClose"
        @move="onWindowMove"
        @resize="onWindowResize"
        @stagechange="onWindowStageChange"
    >
        <load-tender-grid
            v-if="catalogsLoaded && mode === 'list'"
            :values="values"
            @action-dispatched="onLoadTenderGridActionDispatched"
        />
        <section
            v-if="! catalogsLoaded && mode === 'list'"
            class="hero"
        >
            <div class="hero-body">
                <div class="container has-text-centered">
                    <p class="title">
                        {{ $t('loading') }}
                    </p>
                </div>
            </div>
        </section>
        <load-tender-detail
            v-if="mode === 'view'"
            :load-tender="loadTender"
        />
        <load-tender-stop-offs
            v-if="mode === 'stop_offs'"
            :load-tender="loadTender"
            @cancel="onLoadTenderStopOffsCancel"
            @save="onLoadTenderStopOffsSave"
        />
    </window>
</template>

<script>
import {mapGetters} from 'vuex'
import {Window} from '@progress/kendo-vue-dialogs'
import assignIn from 'lodash/assignIn'
import Genesis from '../../../services/genesis'
import LoadTenderDetail from './LoadTenderDetail'
import LoadTenderGrid from './LoadTenderGrid'
import LoadTenderStopOffs from './LoadTenderStopOffs.vue'
import UserService from '../../../services/UserService'

const TITLE_HEIGHT = 54
export default {
    name: 'LoadTenderWindow',
    components: {
        LoadTenderDetail,
        LoadTenderGrid,
        LoadTenderStopOffs,
        Window,
    },
    props: {
        values: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            window_height: 450,
            window_left: 0,
            window_top: 0,
            window_width: 900,
            mode: 'list',
            loadTender: null,
        }
    },
    computed: {
        catalogsLoaded() {
            return this.$store.state.viewModelData &&
                Object.prototype.hasOwnProperty.call(this.$store.state.viewModelData, 'catalogs') &&
                Object.prototype.hasOwnProperty.call(
                    this.$store.state.viewModelData.catalogs,
                    Laravel.CatalogType.CustomerGroup,
                ) &&
                Object.prototype.hasOwnProperty.call(
                    this.$store.state.viewModelData.catalogs,
                    Laravel.CatalogType.EdiTransactionSetPurposeCode,
                )
        },
    },
    async mounted() {
        this.window_top = (this.$el.parentElement.clientHeight - this.window_height) / 2
        this.window_left = (this.$el.parentElement.clientWidth - this.window_width) / 2

        await this.$store.dispatch('CatalogModule/loadCatalogs', [Laravel.CatalogType.FreightStatus])
        this.initViewModelData()
    },
    methods: {
        async initViewModelData() {
            if (! this.catalogsLoaded) {
                let response
                try {
                    response = await UserService.notificationViewModel(Laravel.user.id)
                } catch (error) {
                    this.$store.dispatch('handleAjaxError', {
                        error,
                        message_key: 'loadTender.window.viewModelFail',
                    })
                    this.onWindowClose()
                    return
                }

                const viewModelData = assignIn({}, this.$store.state.viewModelData, response)
                this.$store.commit('setViewModelData', viewModelData)
            }
        },
        async onLoadTenderGridActionDispatched(payload) {
            if (payload.action === 'accept') {
                if (payload.dataItem.purpose_code === Laravel.EdiTransactionSetPurposeCode.Cancellation) {
                    this.$emit('responseLoadTender', {
                        loadTender: payload.dataItem,
                        reservation_action_code: Laravel.ReservationAction.RESERVATION_ACCEPTED,
                    })
                    return
                }

                const loadTender = await checkEdiLocations.call(this, payload)
                if (! loadTender) {
                    return
                }
                const has_unassigned_edi_location = loadTender.data['stop_offs'].some(o => o.locality_id === null)
                if (has_unassigned_edi_location) {
                    this.mode = 'stop_offs'
                    this.loadTender = loadTender
                    return
                } else {
                    await updateLoadTenderStopOffs.call(this, loadTender)
                    return
                }
            }

            if (payload.action !== 'view') {
                redirectToRoute.call(this, payload)
            }

            this.mode = payload.action === 'view' ? 'view' : 'list'
            this.loadTender = payload.dataItem
        },
        async onLoadTenderStopOffsCancel() {
            this.mode = 'list'
        },
        async onLoadTenderStopOffsSave(loadTender) {
            await updateLoadTenderStopOffs.call(this, loadTender)
        },
        onWindowClose() {
            this.$emit('close')
        },
        onWindowMove(event) {
            this.window_left = event.left
            this.window_top = event.top
        },
        onWindowResize(event) {
            this.window_left = event.left
            this.window_top = event.top
            this.window_width = event.width
            this.window_height = event.height
        },
        onWindowStageChange(e, component, actionsEvent) {
            if (actionsEvent.state === 'MINIMIZED') {
                this.window_height = 0
                this.window_width = 350
                this.window_top = this.$el.parentElement.clientHeight - TITLE_HEIGHT
                this.window_left = this.$el.parentElement.clientWidth - this.window_width
            }

            if (actionsEvent.state === 'DEFAULT') {
                this.window_height = 450
                this.window_width = 900
                this.window_top = (this.$el.parentElement.clientHeight - this.window_height) / 2
                this.window_left = (this.$el.parentElement.clientWidth - this.window_width) / 2
            }
        },
    },
}

async function checkEdiLocations(payload) {
    let loadTender
    try {
        loadTender = await Genesis.traffic.loadTender.show(payload.dataItem.id)
    } catch (error) {
        await this.$store.dispatch('handleAjaxError', {
            error,
            message_key: 'loadTender.showFailed',
        })
        return false
    }

    let ediLocations
    try {
        ediLocations = await Genesis.traffic.loadTender.ediLocations(loadTender.id)
    } catch (error) {
        await this.$store.dispatch('handleAjaxError', {
            error,
            message_key: 'loadTender.getEdiLocationsFailed',
        })
        return false
    }

    loadTender.data['stop_offs'] = loadTender.data['stop_offs']
        .map(function (item) {
            const ediLocation = ediLocations.find(o => {
                return o.external_id === item.edi_location_id && o.customer_group_id === loadTender.customer_group_id
            })
            item.locality_id = ediLocation ? ediLocation.locality_id : null
            return item
        })
    return loadTender
}

function redirectToRoute(payload) {
    const routeParams = getRouteParams(payload)
    const resolvedRoute = this.$router.resolve(routeParams)
    this.$store.dispatch('openWindow', {
        window,
        route: resolvedRoute,
        name: routeParams.name,
    })
}

function getRouteParams(payload) {
    switch (payload.action) {
        case 'accept':
            return {
                name: payload.dataItem.freight_id ? 'freights.edit' : 'freights.create',
                params: payload.dataItem.freight_id ? {id: payload.dataItem.freight_id} : undefined,
                query: {popup: true, load_tender_id: payload.dataItem.id},
            }
        case 'reject':
            return {
                name: payload.dataItem.freight_id ? 'freights.show' : 'load_tenders.reject',
                params: {id: payload.dataItem.freight_id ? payload.dataItem.freight_id : payload.dataItem.id},
                query: {
                    popup: true,
                    load_tender_id: payload.dataItem.freight_id ? payload.dataItem.id : undefined,
                },
            }
        case 'view':
            return undefined
        default:
            throw new Error(`Unsupported action "${payload.action}"`)
    }
}

async function updateLoadTenderStopOffs(loadTender) {
    try {
        await Genesis.traffic.loadTender.updateStopOffs(loadTender)
    } catch (error) {
        const contextError = {
            error,
            message_key: 'loadTender.updateFailed',
        }

        if (error.response && error.response.status === Laravel.HttpStatusCode.UnprocessableEntity) {
            contextError.configuration = {duration: 6000}
            contextError.details = []
            for (let error_key in error.response.data.errors) {
                contextError.details = contextError.details.concat(error.response.data.errors[error_key])
            }
        }

        await this.$store.dispatch('handleAjaxError', contextError)
        return
    } finally {
        this.mode = 'list'
    }

    redirectToRoute.call(this, {
        action: 'accept',
        dataItem: loadTender,
    })
}
</script>
