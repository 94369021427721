/**
 * Part of Genesis application
 *
 * @copyright 2022 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import creditNote from './creditNote'
import customer from './customer'
import fiscalVoucher from './fiscalVoucher'
import fiscalVoucherAddenda from './FiscalVoucherAddenda'
import fiscalVoucherCancellation from './fiscalVoucherCancellation'
import fiscalVoucherDownload from './fiscalVoucherDownload'
import fiscalVoucherReplacementChain from './FiscalVoucherReplacementChain'
import freight from './Freight'
import invoice from './invoice'
import voucherFolioSeries from './voucherFolioSeries'

export const Billing = {
    creditNote,
    customer,
    fiscalVoucher,
    fiscalVoucherAddenda,
    fiscalVoucherCancellation,
    fiscalVoucherDownload,
    fiscalVoucherReplacementChain,
    freight,
    invoice,
    voucherFolioSeries,
}

export default Billing