<template>
    <grid
        :data-items="values"
        :columns="gridColumns"
        style="height: 100%"
    >
        <grid-no-records>
            {{ $t('noResultsFound') }}
        </grid-no-records>
        <template #actions-cell="{ props }">
            <actions-cell
                :data-item="props.dataItem"
                :actions="[
                    {
                        action: 'load_tenders',
                        titleKey: 'loadTenders.loadTenderHistory.grid.actions.load_tender.title',
                        labelKey: 'loadTenders.loadTenderHistory.grid.actions.load_tender.label',
                        iconClasses: ['fas','fa-download'],
                        isVisible: () => true,
                    },
                    {
                        action: 'load_tender_responses',
                        titleKey: 'loadTenders.loadTenderHistory.grid.actions.load_tender_response.title',
                        labelKey: 'loadTenders.loadTenderHistory.grid.actions.load_tender_response.label',
                        iconClasses: ['fas','fa-download'],
                        isVisible: (dataItem) => dataItem.load_tender_response_edi_transaction_id !== null,
                    },
                    {
                        action: 'functional_acknowledgements',
                        titleKey: 'loadTenders.loadTenderHistory.grid.actions.functional_acknowledgement.title',
                        labelKey: 'loadTenders.loadTenderHistory.grid.actions.functional_acknowledgement.label',
                        iconClasses: ['fas','fa-download'],
                        isVisible: () => true,
                    },
                ]"
                :actions-callback="(action) => {
                    $emit('action-dispatched', { action, dataItem:props.dataItem })
                }"
            />
        </template>
    </grid>
</template>
<script>
import {Grid, GridNoRecords} from '@progress/kendo-vue-grid'
import {mapGetters} from 'vuex'
import ActionsCell from '../../../../components/kendo-grid/ActionsCell.vue'
import moment from 'moment'

export default {
    name: 'LoadTenderHistoryGrid',
    components: {
        ActionsCell,
        Grid,
        GridNoRecords,
    },
    props: {
        values: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            gridColumns: [],
        }
    },
    watch: {
        '$i18n.locale': function () {
            this.gridColumns.forEach((column) => {
                column.title = this.$t(`loadTenders.loadTenderHistory.grid.columns.${column.field}`)
            })
        },
    },
    computed: {
        ...mapGetters({
            'getCatalog': 'CatalogModule/getCatalog',
        }),
        loadTenderStatus() {
            return this.getCatalog(Laravel.CatalogType.LoadTenderStatus)
        },
        purposeCodes() {
            return this.getCatalog(Laravel.CatalogType.EdiTransactionSetPurposeCode)
        },
    },
    created() {
        const vm = this
        this.gridColumns = [
            {
                field: 'shipment_id',
                title: this.$t('loadTenders.loadTenderHistory.grid.columns.shipment_id'),
            },
            {
                field: 'purpose_code',
                title: this.$t('loadTenders.loadTenderHistory.grid.columns.purpose_code'),
                cell: (h, tdElement, props) => {
                    return h(
                        'td',
                        tdElement.data,
                        vm.purposeCodes.find(c => c.id === props.dataItem.purpose_code).name,
                    )
                },
            },
            {
                field: 'freight_id',
                title: this.$t('loadTenders.loadTenderHistory.grid.columns.freight_id'),
            },
            {
                field: 'created_at',
                title: this.$t('loadTenders.loadTenderHistory.grid.columns.created_at'),
                cell: (h, tdElement, props) => {
                    return h(
                        'td',
                        tdElement.data,
                        moment(props.dataItem.created_at).format('YYYY-MM-DD HH:mm'),
                    )
                },
            },
            {
                field: 'acknowledged_at',
                title: this.$t('loadTenders.loadTenderHistory.grid.columns.acknowledged_at'),
                cell: (h, tdElement, props) => {
                    return h(
                        'td',
                        tdElement.data,
                        props.dataItem.acknowledged_at ?
                            moment(props.dataItem.acknowledged_at).format('YYYY-MM-DD HH:mm') :
                            null,
                    )
                },
            },
            {
                field: 'response_date',
                title: this.$t('loadTenders.loadTenderHistory.grid.columns.response_date'),
                cell: (h, tdElement, props) => {
                    return h(
                        'td',
                        tdElement.data,
                        props.dataItem.response_date ?
                            moment(props.dataItem.response_date).format('YYYY-MM-DD HH:mm') :
                            null,
                    )
                },
            },
            {
                field: 'status',
                title: this.$t('loadTenders.loadTenderHistory.grid.columns.status'),
                cell: (h, tdElement, props) => {
                    return h(
                        'td',
                        tdElement.data,
                        vm.loadTenderStatus.find(c => c.id === props.dataItem.status).name,
                    )
                },
            },
            {
                field: 'answered_by',
                title: this.$t('loadTenders.loadTenderHistory.grid.columns.answered_by'),
            },
            {
                field: 'actions',
                title: this.$t('loadTenders.loadTenderHistory.grid.columns.actions'),
                cell: 'actions-cell',
                width: 250,
            },
        ]
    },
}
</script>