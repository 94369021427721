<template>
    <k-input
        ref="input"
        v-model="value"
        @input="onInput"
    />
</template>
<script>
import {debounce} from 'lodash'
import {Input} from '@progress/kendo-vue-inputs'

export default {
    name: 'TextFilter',
    components: {
        KInput: Input,
    },
    props: {
        field: {
            type: String,
            required: true,
        },
        initialValue: {
            type: String,
            default: '',
        },
        operator: {
            type: String,
            default: 'contains',
        },
    },
    data() {
        return {
            value: null,
        }
    },
    mounted() {
        this.value = this.initialValue
    },
    methods: {
        onInput: debounce(function (event) {
            this.$emit('input', {
                operator: this.operator,
                field: this.field,
                value: event.value.trim(),
            })
        }, 850),
    },
}
</script>
