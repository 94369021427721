/**
 * Part of Genesis application
 *
 * @copyright 2024 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import axios from 'axios'

export const LocationsDistance = {
    async list(params) {
        const response = await axios.get(`/api/locations_distances`, {responseType: 'json', params})

        return response.data.data
    },
    async store(data) {
        const response = await axios.post(`/api/locations_distances`, data, {responseType: 'json'})

        return response.data.data
    },
}

export default LocationsDistance