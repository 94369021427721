/**
 * Part of Genesis application
 *
 * @copyright 2021 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

export const Item = {
    async store(id, source, data) {
        const response = await axios.post(
            `/api/invoices/${id}/items`,
            {
                source,
                data
            },
            { responseType: 'json' })
        return response.data
    }
}

export default Item