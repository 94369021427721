/**
 * Part of Genesis application
 *
 * @copyright 2022 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

export const FiscalVoucherAddenda = {
    async get(fiscal_voucher_id, fiscal_voucher_addenda_id) {
        const response = await axios.get(
            `/api/invoicing_vouchers/${fiscal_voucher_id}/fiscal_voucher_addendas/${fiscal_voucher_addenda_id}`,
            {responseType: 'json'},
        )

        return response.data.data
    },
    async store(fiscal_voucher_id, data) {
        const response = await axios.post(
            `/api/invoicing_vouchers/${fiscal_voucher_id}/fiscal_voucher_addendas`,
            data,
            {responseType: 'json'}
        )
    },
    async update(fiscal_voucher_id, fiscal_voucher_addenda_id, data) {
        const response = await axios.patch(
            `/api/invoicing_vouchers/${fiscal_voucher_id}/fiscal_voucher_addendas/${fiscal_voucher_addenda_id}`,
            data,
            {responseType: 'json'}
        )
    },
}

export default FiscalVoucherAddenda