/**
 * Part of Genesis application
 *
 * @copyright 2020 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import loadTenderResponse from './services/loadTenderResponse'

const propertyMap = {
    'authorization': {
        url: '/api/freight_movements/[FreightMovement]/update_authorization',
        result: (data) => [
            {
                property: 'authorization',
                newValue: data.autorizacion,
            },
        ],
    },
    'border_crossing_id': {
        url: '/api/freight_movements/[FreightMovement]/update_border_crossing',
        result: (data) => [
            {
                property: 'border_crossing_id',
                newValue: data.border_crossing === null ? null : data.border_crossing.id,
            },
        ],
    },
    'destination_id': {
        url: '/api/freight_movements/[FreightMovement]/update_destination',
        result: (data) => [
            {
                property: 'destination_id',
                newValue: data.destination_locality.id,
            },
        ],
    },
    'driver_id': {
        url: '/api/freight_movements/[FreightMovement]/update_driver',
        result: (data) => [
            {
                property: 'driver_id',
                newValue: data.freight_driver === null ? null : data.freight_driver.id,
            },
            {
                property: 'tractor_id',
                newValue: data.tractor === null ? null : data.tractor.id,
            },
            {
                property: 'gps_tractor_name',
                newValue: data.gps_tractor_name,
            },
        ],
    },
    'second_driver_id': {
        url: '/api/freight_movements/[FreightMovement]/update_second_driver',
        result: (data) => [
            {
                property: 'second_driver_id',
                newValue: data.second_driver_id,
            },
        ],
    },
    'freight_movement_type_id': {
        url: '/api/freight_movements/[FreightMovement]/update_movement_type',
        result: (data) => [
            {
                property: 'freight_movement_type_id',
                newValue: data.freight_movement_type.id,
            },
        ],
    },
    'full_inspection_mex_event_id': {
        url: '/api/freight_movements/[FreightMovement]/update_full_inspection_mex',
        result: (data) => [
            {
                property: 'full_inspection_mex_event_id',
                newValue: data.full_inspection_mex_event_id,
            },
        ],
    },
    'origin_id': {
        url: '/api/freight_movements/[FreightMovement]/update_origin',
        result: (data) => [
            {
                property: 'origin_id',
                newValue: data.origin_locality.id,
            },
        ],
    },
    'status': {
        url: '/api/freight_movements/[FreightMovement]/update_status',
        result: (data) => [
            {
                property: 'status',
                newValue: data.last_status.id,
            },
        ],
    },
    'ticket': {
        url: '/api/freight_movements/[FreightMovement]/update_ticket',
        result: (data) => [
            {
                property: 'ticket',
                newValue: data.boleta,
            },
        ],
    },
    'tractor_id': {
        url: '/api/freight_movements/[FreightMovement]/update_tractor',
        result: (data) => [
            {
                property: 'tractor_id',
                newValue: data.tractor === null ? null : data.tractor.id,
            },
            {
                property: 'gps_tractor_name',
                newValue: data.gps_tractor_name,
            },
        ],
    },
    'trailer_id': {
        url: '/api/freight_movements/[FreightMovement]/update_trailer',
        result: (data) => [
            {
                property: 'trailer_id',
                newValue: data.trailer === null ? null : data.trailer.id,
            },
            {
                property: 'trailer_name',
                newValue: data.trailer === null ? null : data.trailer.name,
            },
            {
                property: 'second_trailer_id',
                newValue: data.second_trailer_id,
            },
            {
                property: 'second_trailer_name',
                newValue: data.second_trailer_name,
            },
        ],
    },
    'second_trailer_id': {
        url: '/api/freight_movements/[FreightMovement]/update_second_trailer',
        result: (data) => [
            {
                property: 'second_trailer_id',
                newValue: data.second_trailer_id,
            },
            {
                property: 'second_trailer_name',
                newValue: data.second_trailer_name,
            },
        ],
    },
    'customer_branch_id': {
        url: '/api/freight_movements/[FreightMovement]/update_customer_branch',
        result: (data) => [
            {
                property: 'customer_branch_id',
                newValue: data.customer_branch_id,
            },
        ],
    },
}

const eventTypes = [
    Laravel.FreightMovementEventType.ID_ORIGIN_ARRIVAL,
    Laravel.FreightMovementEventType.ID_ORIGIN_DEPARTURE,
    Laravel.FreightMovementEventType.ID_PRECOS_ARRIVAL,
    Laravel.FreightMovementEventType.ID_PRECOS_DEPARTURE,
    Laravel.FreightMovementEventType.ID_CUSTOMS_MEX_ARRIVAL,
    Laravel.FreightMovementEventType.ID_CUSTOMS_MEX_DEPARTURE,
    Laravel.FreightMovementEventType.ID_CUSTOMS_US_ARRIVAL,
    Laravel.FreightMovementEventType.ID_CUSTOMS_US_DEPARTURE,
    Laravel.FreightMovementEventType.ID_DESTINATION_ARRIVAL,
]

async function updateFreightTripColumns(freight_trip_id, property, value, transform = true) {
    const url = propertyMap[property].url
    const response = await axios({
        url: url.replace('[FreightMovement]', freight_trip_id),
        method: 'PATCH',
        data: value,
    })

    let result = response.data.data
    if (transform) {
        result = propertyMap[property].result(response.data.data)
        result.push({
            property: 'lock_version',
            newValue: response.data.data.lock_version,
        })
    }

    return result

}

async function updateFreightTripProperty(property, newValue, data, transform = true) {
    switch (property) {
        case 'authorization':
        case 'border_crossing_id':
        case 'destination_id':
        case 'customer_branch_id':
        case 'driver_id':
        case 'second_driver_id':
        case 'freight_movement_type_id':
        case 'full_inspection_mex_event_id':
        case 'origin_id':
        case 'status':
        case 'ticket':
        case 'tractor_id':
        case 'trailer_id':
        case 'second_trailer_id':
            return await updateFreightTripColumns(
                data.id || data.traslado_id,
                property,
                {...getData(property, newValue, data), lock_version: data.lock_version},
                transform,
            )
        default:
            throw new Error(`Property ${property} not implemented.`)
    }
}

function getData(property, newValue) {
    switch (property) {
        case 'status':
            return {freight_movement_status_type_id: newValue}
        case 'full_inspection_mex_event_id':
            return {event_type_id: newValue}
        case 'authorization':
        case 'border_crossing_id':
        case 'destination_id':
        case 'customer_branch_id':
        case 'driver_id':
        case 'second_driver_id':
        case 'freight_movement_type_id':
        case 'origin_id':
        case 'ticket':
        case 'tractor_id':
            return {[property]: newValue}
        case 'second_trailer_id':
            return {[property]: newValue ? newValue.id : null}
        case 'trailer_id':
            return newValue === null ?
                {
                    trailer_type_id: Laravel.TrafficTrailerType.ID_WITHOUT_TRAILER,
                    trailer_id: null,
                    trailer_name: null,
                } :
                (
                    newValue.id === Laravel.TrafficTrailer.ID_THIRD_PARTY ?
                        {
                            trailer_type_id: Laravel.TrafficTrailerType.ID_THIRD_PARTY,
                            trailer_id: null,
                            trailer_name: newValue.name,
                        } :
                        {
                            trailer_type_id: Laravel.TrafficTrailerType.ID_OWN,
                            trailer_id: newValue.id,
                            trailer_name: null,
                        }
                )
        default:
    }
}

async function createFreightTripEvent(event_type_id, occurred_at, data) {
    if (! eventTypes.includes(event_type_id)) {
        throw new Error(`Event ID ${event_type_id} not supported.`)
    }

    const freight_trip_id = Object.prototype.hasOwnProperty.call(data, 'traslado_id') ?
        data.traslado_id :
        data.id
    const response = await axios({
        url: '/api/freight_movements/[FreightMovement]/freight_movement_events/store'
            .replace('[FreightMovement]', freight_trip_id),
        method: 'POST',
        data: {
            lock_version: data.lock_version,
            event_type_id,
            occurred_at,
        },
    })
    return response.data.data
}

async function createTrip(freight_id, freightTripData) {
    const data = {
        freight_lock_version: freightTripData.freight_lock_version,
        origin_id: freightTripData.origin_id,
        destination_id: freightTripData.destination_id,
        type_id: freightTripData.type_id,
        trailer_type_id: freightTripData.trailer_type_id,
        trailer_id: freightTripData.trailer_id,
        trailer_name: freightTripData.trailer_name,
        sequence_number: freightTripData.order,
    }

    const url = `/api/freights/${freight_id}/freight_trips/store`
    const response = await axios({
        url,
        method: 'POST',
        data,
    })
    return response.data
}

async function completeFreightTrip(freight_trip_id, data) {
    const response = await axios({
        method: 'PATCH',
        url: `/api/freight_movements/${freight_trip_id}/complete`,
        responseType: 'json',
        data,
    })

    return response.data.data
}

async function finalizeFreightStatusToFalseStart(freight_id, data) {
    await axios({
        method: 'PATCH',
        url: `/api/freights/${freight_id}/set_status_to_false_start`,
        responseType: 'json',
        data,
    })
}

async function getGridConfiguration(grid_configuration_id) {
    const response = await axios({
        method: 'GET',
        url: `/api/grid_configurations/${grid_configuration_id}`,
        responseType: 'json',
    })
    return response.data.data
}

async function setDefaultGridConfiguration(grid_type_id, grid_configuration_id) {
    const response = await axios({
        method: 'PATCH',
        url: `/api/grid/${grid_type_id}/configuration/set_default`,
        responseType: 'json',
        data: {
            grid_configuration_id,
        },
    })
    return response.data
}

async function deleteGridConfiguration(grid_configuration_id) {
    await axios({
        method: 'DELETE',
        url: `/api/grid_configurations/${grid_configuration_id}/delete`,
        responseType: 'json',
        data: {
            grid_configuration_id,
        },
    })
}

async function getViewModel() {
    const response = await axios({
        method: 'GET',
        url: '/api/freights/view_model',
        responseType: 'json',
    })
    return response.data
}

async function createFreightTripComment(freight_trip_id, comment) {
    const response = await axios({
        method: 'POST',
        url: `/api/freight_movements/${freight_trip_id}/freight_movement_comments/store`,
        responseType: 'json',
        data: {
            freight_movement_comment_type_id: Laravel.FreightMovementComment.COMMENT_TYPE_CREATION,
            comment,
        },
    })

    return response.data.data
}

async function getFreightTypeViewModel() {
    const response = await axios({
        method: 'GET',
        url: '/api/freight_types/view_model',
        responseType: 'json',
    })
    return response.data
}

async function createFreightType(freight_type_data) {
    const response = await axios({
        method: 'POST',
        url: '/api/freight_types',
        responseType: 'json',
        data: freight_type_data,
    })
    return response.data
}

async function getFreightTemplate(freight_template_id) {
    const response = await axios({
        method: 'GET',
        url: `/api/data/freight_templates/${freight_template_id}`,
        responseType: 'json',
    })
    return response.data.data
}

async function getFreightTemplateViewModel() {
    const response = await axios({
        method: 'GET',
        url: '/api/freight_templates/view_model',
        responseType: 'json',
    })
    return response.data
}

async function createFreight(freight, load_tender_response_remarks) {
    const data = cloneDeep(freight)
    data.shipments = data.shipments.map((trip) => {
        const trailer_type_id = trip.trailer_id === null ? Laravel.TrafficTrailerType.ID_WITHOUT_TRAILER : Laravel.TrafficTrailerType.ID_OWN

        return {
            ...trip,
            trailer_name: trip.trailer_name || null,
            trailer_type_id,
        }
    })

    if (data.load_tender_id && load_tender_response_remarks) {
        data.load_tender_response_remarks = load_tender_response_remarks
    }
    const response = await axios({
        method: 'POST',
        url: '/api/freights',
        responseType: 'json',
        data,
    })

    return response.data
}

async function updateFreight(freight, comment_text = null, load_tender_response_remarks = null) {
    freight.shipments = freight.shipments.map((trip, index) => {
        const trailer_type_id = trip.trailer_id === null ? Laravel.TrafficTrailerType.ID_WITHOUT_TRAILER : Laravel.TrafficTrailerType.ID_OWN

        return {
            ...trip,
            order: index + 1,
            trailer_name: trip.trailer_name || null,
            trailer_type_id,
        }
    })

    const data = {...freight}
    if (comment_text) {
        data.comment = comment_text
    }

    if (load_tender_response_remarks) {
        data.load_tender_response_remarks = load_tender_response_remarks
    }

    const response = await axios({
        method: 'PATCH',
        url: `/api/freights/${freight.id}`,
        responseType: 'json',
        data,
    })

    return response.data
}

async function updateFreightType(freightType) {
    const response = await axios({
        method: 'PATCH',
        url: `/api/freight_types/${freightType.id}`,
        responseType: 'json',
        data: {
            code: freightType.code,
            description: freightType.description,
        },
    })

    return response.data
}

async function getFreightType(freight_type_id) {
    const response = await axios({
        method: 'GET',
        url: `/api/data/freight_types/${freight_type_id}`,
        responseType: 'json',
    })
    return response.data.data
}

async function getFreightTrip(freight_trip_id) {
    const response = await axios({
        method: 'GET',
        url: `/api/freight_movements/${freight_trip_id}`,
        responseType: 'json',
    })
    return response.data.data
}

async function cancelFreightTrip(freight_trip_id, data) {
    const response = await axios({
        method: 'PATCH',
        url: `/api/freight_movements/${freight_trip_id}/cancel`,
        responseType: 'json',
        data,
    })

    return response.data.data
}

async function getLocalities() {
    const response = await axios({
        method: 'GET',
        url: '/api/freight_localities',
        responseType: 'json',
    })

    return response.data ? response.data.data : []
}

async function completeFreight(freight_id, data) {
    await axios({
        url: `/api/freights/${freight_id}/complete`,
        method: 'PATCH',
        data,
        responseType: 'json',
    })

    return true
}

async function answerFreightCancellation(freight_id, data) {
    await axios({
        url: `/api/freights/${freight_id}/answer_cancellation_request`,
        method: 'PATCH',
        data,
        responseType: 'json',
    })

    return true
}

async function requestFreightCancellation(freight_id, data) {
    await axios({
        url: `/api/freights/${freight_id}/request_cancellation`,
        method: 'PATCH',
        data,
        responseType: 'json',
    })

    return true
}

async function activateFreight(freight_id, data) {
    await axios({
        url: `/api/freights/${freight_id}/activate`,
        method: 'PATCH',
        data,
        responseType: 'json',
    })

    return true
}

async function revertFreightTripStatus(freight_trip_id, data) {
    const response = await axios({
        url: `/api/freight_movements/${freight_trip_id}/revert_status`,
        method: 'PATCH',
        data,
        responseType: 'json',
    })

    return response.data.data
}

async function getLoadTenderHtmlContent(load_tender_id) {
    const response = await axios({
        method: 'GET',
        url: `/api/load_tenders/${load_tender_id}/html_content`,
    })

    return response.data
}

async function getLocalityViewModel() {
    const response = await axios({
        method: 'GET',
        url: '/api/freight_localities/view_model',
        responseType: 'json',
    })
    return response.data
}

async function assignGeofencesToLocality(locality_id, data) {
    const response = await axios({
        method: 'PATCH',
        url: `/api/freight_localities/${locality_id}/assign_geofences`,
        responseType: 'json',
        data,
    })

    return response.data.data
}

async function getFreightLocalities(freight_id) {
    const response = await axios({
        method: 'GET',
        url: `/api/freights/${freight_id}/localities`,
        responseType: 'json',
    })
    return response.data
}

async function getDriverViewModel() {
    const response = await axios({
        method: 'GET',
        url: '/api/freight_drivers/view_model',
        responseType: 'json',
    })
    return response.data
}

async function getReportViewModel() {
    const response = await axios({
        method: 'GET',
        url: '/api/reports/view_model',
        responseType: 'json',
    })

    return response.data
}

export default {
    activateFreight,
    answerFreightCancellation,
    assignGeofencesToLocality,
    cancelFreightTrip,
    completeFreight,
    completeFreightTrip,
    createFreight,
    createFreightTripComment,
    createFreightTripEvent,
    createFreightType,
    createTrip,
    deleteGridConfiguration,
    finalizeFreightStatusToFalseStart,
    getDriverViewModel,
    getFreightLocalities,
    getFreightTemplate,
    getFreightTemplateViewModel,
    getFreightTrip,
    getFreightType,
    getFreightTypeViewModel,
    getGridConfiguration,
    getLoadTenderHtmlContent,
    getLocalities,
    getLocalityViewModel,
    getReportViewModel,
    getViewModel,
    loadTenderResponse,
    requestFreightCancellation,
    revertFreightTripStatus,
    setDefaultGridConfiguration,
    updateFreight,
    updateFreightTripProperty,
    updateFreightType,
}
