/**
 * Part of Genesis application
 *
 * @copyright 2021 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

export const ProductService = {
    async list(term, max_rows) {
        const params = {
            term,
            max_rows,
        }
        const response = await axios.get('/api/product_services/search', { params, responseType: 'json' })
        return response.data.data
    },
}

export default ProductService