/**
 * Part of Genesis application
 *
 * @copyright 2022 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

export const StampError = {
    async list(fiscal_voucher_id, params) {
        const response = await axios.get(
            `/api/invoicing_vouchers/${fiscal_voucher_id}/stamp_errors`,
            {responseType: 'json', params},
        )

        return response.data.data
    },
    async update(fiscal_voucher_id, stamp_error_id, data) {
        const response = await axios.patch(
            `/api/invoicing_vouchers/${fiscal_voucher_id}/stamp_errors/${stamp_error_id}`,
            data,
            {responseType: 'json'}
        )
        return response.data.data
    },
}

export default StampError