/**
 * Part of Genesis application
 *
 * @copyright 2022 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

export const DeparturePass = {
    async store(freight_trip_id, departurePass) {
        return await axios.post(
            `/api/freight_trips/${freight_trip_id}/departure_passes`,
            departurePass,
            {responseType: 'blob'},
        )
    },
}

export default DeparturePass