<template>
    <grid
        :data-items="items"
        :columns="gridColumns"
        style="height: 100%"
    >
        <grid-no-records>
            {{ $t('noResultsFound') }}
        </grid-no-records>
        <template #actions-cell="{ props }">
            <actions-cell
                :data-item="props.dataItem"
                :actions="[
                    {
                        action: 'accept',
                        titleKey: 'loadTender.grid.actions.accept',
                        iconClasses: [
                            'fas',
                            'fa-check',
                        ],
                        isVisible: () => true,
                    },
                    {
                        action: 'reject',
                        titleKey: 'loadTender.grid.actions.reject',
                        iconClasses: [
                            'fas',
                            'fa-times'
                        ],
                        isVisible: () => true,
                    },
                    {
                        action: 'view',
                        titleKey: 'loadTender.grid.actions.view',
                        iconClasses: [
                            'fas',
                            'fa-eye'
                        ],
                        isVisible: () => true,
                    },
                ]"
                :actions-callback="(action) => {
                    $emit('action-dispatched', { action, dataItem:props.dataItem })
                }"
            />
        </template>
    </grid>
</template>
<script>
import {Grid, GridNoRecords} from '@progress/kendo-vue-grid'
import {mapGetters} from 'vuex'
import ActionsCell from '../../../components/kendo-grid/ActionsCell'
import cloneDeep from 'lodash/cloneDeep'

export default {
    name: 'LoadTenderGrid',
    components: {
        ActionsCell,
        Grid,
        GridNoRecords,
    },
    props: {
        values: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            gridColumns: [],
            items: [],
        }
    },
    watch: {
        '$i18n.locale': function () {
            this.gridColumns.forEach((column) => {
                column.title = this.$t(`loadTender.grid.columns.${column.field}`)
            })
        },
        values() {
            initializeDateColumns.call(this)
        },
    },
    computed: {
        ...mapGetters({'getCatalog': 'CatalogModule/getCatalog'}),
        freightStatus() {
            return this.getCatalog(Laravel.CatalogType.FreightStatus)
        },
    },
    created() {
        initializeDateColumns.call(this)
        const vm = this
        this.gridColumns = [
            {
                field: 'customer',
                title: this.$t('loadTender.grid.columns.customer'),
                cell: (h, tdElement, props) => {
                    return h(
                        'td',
                        tdElement.data,
                        vm.$store.state.viewModelData.catalogs[Laravel.CatalogType.CustomerGroup]
                            .find(c => c.id === props.dataItem.customer_group_id).name,
                    )
                },
            },
            {
                field: 'shipment_id',
                title: this.$t('loadTender.grid.columns.shipment_id'),
            },
            {
                field: 'freight_id',
                title: this.$t('loadTender.grid.columns.freight_id'),
            },
            {
                field: 'freight_status',
                title: this.$t('loadTender.grid.columns.freight_status'),
                cell: (h, tdElement, props) => {
                    return h(
                        'td',
                        tdElement.data,
                        this.freightStatus.find(c => c.id === props.dataItem.freight_status)?.name,
                    )
                },
            },
            {
                field: 'purpose_code',
                title: this.$t('loadTender.grid.columns.purpose_code'),
                cell: (h, tdElement, props) => {
                    return h(
                        'td',
                        tdElement.data,
                        vm.$store.state.viewModelData.catalogs[Laravel.CatalogType.EdiTransactionSetPurposeCode]
                            .find(c => c.id === props.dataItem.purpose_code).name,
                    )
                },
            },
            {
                field: 'service_date',
                title: this.$t('loadTender.grid.columns.service_date'),
                format: '{0:y-MM-dd HH:mm}',
                type: 'date',
            },
            {
                field: 'origin',
                title: this.$t('loadTender.grid.columns.origin'),
            },
            {
                field: 'origin_date_start',
                title: this.$t('loadTender.grid.columns.origin_date_start'),
                format: '{0:y-MM-dd HH:mm}',
                type: 'date',
            },
            {
                field: 'origin_date_end',
                title: this.$t('loadTender.grid.columns.origin_date_end'),
                format: '{0:y-MM-dd HH:mm}',
                type: 'date',
            },
            {
                field: 'stop_offs',
                title: this.$t('loadTender.grid.columns.stop_offs'),
            },
            {
                field: 'destination',
                title: this.$t('loadTender.grid.columns.destination'),
            },
            {
                field: 'destination_date_start',
                title: this.$t('loadTender.grid.columns.destination_date_start'),
                format: '{0:y-MM-dd HH:mm}',
                type: 'date',
            },
            {
                field: 'destination_date_end',
                title: this.$t('loadTender.grid.columns.destination_date_end'),
                format: '{0:y-MM-dd HH:mm}',
                type: 'date',
            },
            {
                field: 'actions',
                title: this.$t('loadTender.grid.columns.actions'),
                cell: 'actions-cell',
            },
        ]
    },
}

function initializeDateColumns() {
    this.items = this.values.map(i => {
        const item = cloneDeep(i)
        item.service_date = item.service_date ? item.service_date.slice(0, -4) + 'Z' : ''
        item.origin_date_start = item.origin_date_start ? item.origin_date_start.slice(0, -4) + 'Z' : ''
        item.origin_date_end = item.origin_date_end ? item.origin_date_end.slice(0, -4) + 'Z' : ''
        item.destination_date_start = item.destination_date_start ? item.destination_date_start.slice(0, -4) + 'Z' : ''
        item.destination_date_end = item.destination_date_end ? item.destination_date_end.slice(0, -4) + 'Z' : ''

        return item
    })
}
</script>