/**
 * Part of Genesis application
 *
 * @copyright 2021 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

export const Vehicle = {
    async destroy(vehicle) {
        await axios.delete(
            `/api/vehicles/${vehicle.id}`,
            {
                data: {lock_version: vehicle.lock_version},
                responseType: 'json',
            },
        )
    },
    async get(vehicle_id) {
        const response = await axios.get(`/api/vehicles/${vehicle_id}`, {responseType: 'json'})
        return response.data.data
    },
    async list(params = undefined) {
        const response = await axios.get('/api/vehicles', {responseType: 'json', params})
        return response.data.data
    },
    async store(data) {
        const result = await axios.post('/api/vehicles', data, {responseType: 'json'})
        return result.data
    },
    async update(data) {
        const result = await axios.patch(`/api/vehicles/${data.id}`, data, {responseType: 'json'})
        return result.data.data
    },
    async updateStatus(data) {
        const result = await axios.patch(`/api/vehicles/${data.id}/update_status`, data, {responseType: 'json'})
        return result.data.data
    },
    async viewModel() {
        const response = await axios.get('/api/vehicles/view_model', {responseType: 'json'})
        return response.data
    },
    async withoutVehicleInsurance() {
        const response = await axios.get('/api/vehicles/without_vehicle_insurance', {responseType: 'json'})
        return response.data.data
    },
}

export default Vehicle