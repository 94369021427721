/**
 * Part of Genesis application
 *
 * @copyright 2022 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

export const FiscalVoucherCancellation = {
    async store(data, document_type) {
        let url = `/api/fiscal_voucher_cancellations?` + Laravel.Genesis.URL_PARAM_DOCUMENT_TYPE + `=${document_type}`
        const response = await axios.post(url, data)

        return response.data
    },
}

export default FiscalVoucherCancellation