/**
 * Part of Genesis application
 *
 * @copyright 2021 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import {assignIn} from 'lodash'
import axios from 'axios'
import moment from 'moment'
import serviceRequestFile from './serviceRequestFile/index'

function transformServiceRequest(serviceRequest) {
    return assignIn(
        {},
        serviceRequest,
        {
            merchandise_file: undefined,
            stops: serviceRequest.stops.map(s => assignIn(
                {},
                s,
                {
                    estimated_departure: moment(s.estimated_departure).toISOString(),
                    estimated_arrival: moment(s.estimated_arrival).toISOString(),
                },
            )),
        },
    )
}

export const ServiceRequest = {
    serviceRequestFile,
    async downloadTemplate() {
        return await axios.get(`/api/service_requests/download_template`, {responseType: 'blob'})
    },
    async store(data) {
        const formData = new FormData()
        formData.append('customer_group_id', data.customer_group_id)
        formData.append('reference', data.reference)
        formData.append('merchandise_file', data.merchandise_file, data.merchandise_file.name)
        const response = await axios.post('/api/service_requests', formData, {
            responseType: 'json',
            headers: {'Content-Type': 'multipart/form-data'},
        })

        return response.data
    },
    async get(service_request_id) {
        const response = await axios.get(`/api/service_requests/${service_request_id}`, {responseType: 'json'})

        return response.data.data
    },
    async list(customer_group_id = null) {
        const response = await axios.get(`/api/service_requests`, {responseType: 'json', params: {customer_group_id}})

        return response.data.data
    },
    async update(serviceRequest) {
        const requestData = transformServiceRequest(serviceRequest)
        const response = await axios.patch(
            `/api/service_requests/${serviceRequest.id}`,
            requestData,
            {responseType: 'json'},
        )

        return response.data.data
    },
}

export default ServiceRequest