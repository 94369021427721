/**
 * Part of Genesis application
 *
 * @copyright 2021 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import Wialon from 'wialonjs-api'

class Gps {
    constructor() {
        this.session = null
        this.gpsUnits = {}
    }

    async initSession() {
        const session = new Wialon.Session(process.env.MIX_GPS_URL, {eventsTimeout: 30})
        return new Promise((resolve, reject) => {
            session.execute('token/login', {token: Laravel.genesisConfiguration.gps.token}, (response) => {
                if (response.error) {
                    reject(null)
                } else {
                    this.session = session
                    resolve(this)
                }
            })
        })
    }

    async getUnit(unit_name) {
        const params = {
            params: {
                'spec': {
                    'itemsType': 'avl_unit',
                    'propName': 'sys_name',
                    'propValueMask': unit_name,
                    'sortType': '',
                    'propType': '',
                },
                'force': '1',
                'flags': 0x411,
                'from': 0,
                'to': 0,
                'or_logic': false,
            },
        }
        return new Promise((resolve, reject) => {
            this.session.execute('core/search_items', params, (data) => {
                if (data.errors) {
                    reject(data.errors)
                }

                if (unit_name === '*') {
                    resolve(data.items)
                    return
                }

                this.gpsUnits[unit_name] = data.items[0].id
                resolve(data.items[0])
            })
        })
    }

    async fetchEvents(unit_name, start_date) {
        if (! Object.prototype.hasOwnProperty.call(this.gpsUnits, unit_name)) {
            return []
        }

        const date_from = Number((new Date(start_date)).getTime() / 1000).toFixed(0)
        const now = Number(Date.now() / 1000).toFixed(0)
        const params = {
            params: {
                'itemId': this.gpsUnits[unit_name],
                'timeFrom': date_from,
                'timeTo': now,
                'flags': 1,
                'flagsMask': 65281,
                'loadCount': 99999,
            },
        }

        return new Promise(resolve => {
            this.session.execute('messages/load_interval', params, function (data) {
                if (! data.error) {
                    resolve(data.messages)
                }
            })
        })
    }

    async registerEvents(unit_name) {
        if (! Object.prototype.hasOwnProperty.call(this.gpsUnits, unit_name)) {
            return null
        }

        return new Promise(resolve => {
            const params = {
                'spec': [
                    {
                        'type': 'col',
                        'data': [this.gpsUnits[unit_name]],
                        'flags': 0x411,
                        'mode': 1,
                    },
                ],
            }
            this.session.execute('core/update_data_flags', params, (data) => {
                resolve(data)
            })
        })
    }

    async unregisterEvents(unit_name) {
        if (! Object.prototype.hasOwnProperty.call(this.gpsUnits, unit_name)) {
            return null
        }

        return new Promise(resolve => {
            const params = {
                'spec': [
                    {
                        'type': 'col',
                        'data': [this.gpsUnits[unit_name]],
                        'flags': 0x411,
                        'mode': 2,
                    },
                ],
            }
            this.session.execute('core/update_data_flags', params, (data) => {
                resolve(data)
            })
        })
    }

    registerPositionEvent(callback) {
        this.session.on('positionChanged', callback)
    }

    async closeSession() {
        if (this.session === null) {
            return this
        }

        return new Promise((resolve) => {
            this.session.execute('core/logout', () => {
                this.session = null
                resolve(this)
            })
        })
    }
}

export default Gps