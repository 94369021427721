/**
 * Part of Genesis application
 *
 * @copyright 2024 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

export const DigitalSealCertificate = {
    async list(issuer_id) {
        const response = await axios.get(`/api/issuers/${issuer_id}/digital_seal_certificates`)

        return response.data.data
    },
    async store(data) {
        const formData = new FormData()
        formData.set('private_key_password', data.private_key_password)
        formData.set('certificate_file', data.certificate_file.getRawFile(), data.certificate_file.name)
        formData.set('private_key_file', data.private_key_file.getRawFile(), data.private_key_file.name)

        const response = await axios.post(`/api/issuers/${data.issuer_id}/digital_seal_certificates`, formData, {
            responseType: 'json',
            headers: {'Content-Type': 'multipart/form-data'},
        })

        return response.data
    },
}

export default DigitalSealCertificate