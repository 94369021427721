/**
 * Part of Genesis application
 *
 * @copyright 2024 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

export const FreightTripType = {
    async list(params) {
        const response = await axios.get('/api/freight_trip_types', {params, responseType: 'json'})

        return response.data.data
    },
    async show(id) {
        const response = await axios.get(`/api/freight_trip_types/${id}`, {responseType: 'json'})

        return response.data.data
    },
    async store(params) {
        const response = await axios.post('/api/freight_trip_types', params, {responseType: 'json'})

        return response.data
    },
    async update(data) {
        const response = await axios.patch(`/api/freight_trip_types/${data.id}`, data, {responseType: 'json'})

        return response.data.data
    },
}

export default FreightTripType