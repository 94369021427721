/**
 * Part of Genesis application
 *
 * @copyright 2023 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import customer from './customer'

export const CustomerGroup = {
    customer,
}

export default CustomerGroup