/**
 * Part of Genesis application
 *
 * @copyright 2021 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import assignIn from 'lodash/assignIn'

export const state = {
    eventCallbacks: {},
}

export const actions = {
    dispatchEvent({ state }, payload) {
        for (const key in state.eventCallbacks[payload.type]) {
            if (! Object.prototype.hasOwnProperty.call(state.eventCallbacks[payload.type], key) ||
                typeof state.eventCallbacks[payload.type][key] !== 'function') {
                continue
            }

            state.eventCallbacks[payload.type][key](...payload.params)
        }
    },
}

export const mutations = {
    registerEventCallback(state, payload) {
        if (state.eventCallbacks[payload.type]) {
            assignIn(state.eventCallbacks[payload.type], { [payload.key]: payload.callback })
            return
        }

        assignIn(state.eventCallbacks, { [payload.type]: { [payload.key]: payload.callback } })
    },
    unregisterEventCallback(state, payload) {
        assignIn(state.eventCallbacks[payload.type], {[payload.key]: undefined})
    },
}

export const EventModule = {
    actions,
    mutations,
    namespaced: true,
    state,
}