<template>
    <div
        :id="modalId"
        class="modal"
        @keyUp.capture.esc="onCloseClick"
    >
        <div
            class="modal-background"
            @click="onCloseClick"
        />
        <div class="modal-card">
            <header class="modal-card-head">
                <p
                    v-t="'feedbackModal.titleModal'"
                    class="modal-card-title"
                />
                <button
                    class="delete"
                    :aria-label="$t('close')"
                    @click="onCloseClick"
                />
            </header>
            <section class="modal-card-body">
                <form>
                    <div class="field">
                        <label
                            class="label"
                            for="feedbackTitle"
                        >
                            {{ $t('feedbackModal.title') }}
                        </label>
                        <input
                            v-model="feedbackTitle"
                            class="input"
                            name="feedbackTitle"
                            :maxlength="titleMaxLength"
                        />
                    </div>
                    <p class="help">
                        {{ titleCharactersLeft }}
                    </p>
                    <div class="field">
                        <label
                            class="label"
                            for="feedbackDescription"
                        >{{ label }}</label>
                        <textarea
                            v-model="feedbackText"
                            class="textarea"
                            name="feedbackDescription"
                            rows="5"
                            :maxlength="maxLength"
                        />
                    </div>
                    <p class="help">
                        {{ charactersLeft }}
                    </p>
                </form>
            </section>
            <footer class="modal-card-foot">
                <button
                    ref="saveButton"
                    :class="[ 'button', 'is-success', { 'is-loading': isSaving } ]"
                    :disabled="disableSave"
                    @click="saveOnClick"
                >
                    {{ $t('Submit') }}
                </button>
                <button
                    class="button"
                    @click="onCloseClick"
                >
                    {{ $t('Cancel') }}
                </button>
            </footer>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import {mapActions} from 'vuex'

export default {
    props: {
        feedbackUrl: {
            type: String,
            required: true,
        },
        modalId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            feedbackText: '',
            feedbackTitle: '',
            isSaving: false,
            maxLength: Laravel.Genesis.MAX_LENGTH_FEEDBACK_DESCRIPTION,
            titleMaxLength: Laravel.Genesis.MAX_LENGTH_FEEDBACK_TITLE,
        }
    },
    computed: {
        label: function () {
            return this.$t('feedbackModal.labelTechnicalSupport')
        },
        charactersLeft: function () {
            var char = this.feedbackText.length
            return (this.maxLength - char) + ' ' + this.$t('characters remaining')
        },
        disableSave: function () {
            return ! this.feedbackText
        },
        titleCharactersLeft: function () {
            var char = this.feedbackTitle.length
            return (this.titleMaxLength - char) + ' ' + this.$t('characters remaining')
        },
    },
    methods: {
        ...mapActions({
            handleAjaxError: 'handleAjaxError',
            closeFeedbackModal: 'closeModal',
            showAlert: 'showAlert',
        }),
        onCloseClick: function () {
            this.closeModal()
        },
        closeModal: function () {
            this.reset()
            this.closeFeedbackModal(this.modalId)
        },
        saveOnClick: function () {
            var vm = this
            axios({
                method: 'post',
                url: vm.feedbackUrl,
                data: {
                    title: this.feedbackTitle,
                    description: this.feedbackText,
                },
                responseType: 'json',
            })
                .then(function () {
                    vm.closeModal()
                    vm.showAlert(vm.$t('feedbackModal.success'))
                })
                .catch(function (error) {
                    var errorContext = {
                        'error': error,
                        'message': vm.$t('feedbackModal.errors.sendFailed'),
                    }

                    vm.handleAjaxError(errorContext)
                })
                .finally(function () {
                    vm.isSaving = false
                })
        },
        reset: function () {
            this.feedbackText = ''
            this.feedbackTitle = ''
        },
    },
}
</script>
