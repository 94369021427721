/**
 * Part of Genesis application
 *
 * @copyright 2023 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

export const LoadTender = {
    async ediLocations(load_tender_id) {
        const response = await axios.get(`/api/load_tenders/${load_tender_id}/edi_locations`, {responseType: 'json'})

        return response.data
    },
    async list(params) {
        const response = await axios.get('/api/load_tenders', {params, responseType: 'json'})

        return response.data.data
    },
    async listHistory(load_tender_id) {
        const response = await axios.get(`/api/load_tenders/${load_tender_id}/history`, {responseType: 'json'})

        return response.data.data
    },
    async show(load_tender_id) {
        const response = await axios.get(`/api/load_tenders/${load_tender_id}`, {responseType: 'json'})

        return response.data
    },
    async update(data) {
        const result = await axios.patch(`/api/load_tenders/${data.id}`, data, {responseType: 'json'})
        return result.data.data
    },
    async updateStopOffs(data) {
        const result = await axios.patch(`/api/load_tenders/${data.id}/stop_offs`, data, {responseType: 'json'})
        return result.data.data
    },
}

export default LoadTender