/**
 * Part of Genesis application
 *
 * @copyright 2021 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

export const LoadTenderResponse = {
    async store(data) {
        const request_data = {
            load_tender_response_remarks: data.load_tender_response_remarks,
            reservation_action_code: data.reservation_action_code,
        }
        const response = await axios.post(
            `/api/load_tenders/${data.load_tender_id}/load_tender_responses`,
            request_data,
            { responseType: 'json'},
        )

        return response.data
    },
}

export default LoadTenderResponse