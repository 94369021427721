/**
 * Part of Genesis application
 *
 * @copyright 2024 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'
import digitalSealCertificate from './digitalSealCertificate'

export const Issuer = {
    digitalSealCertificate,
    async list(params) {
        const response = await axios.get('/api/issuers', {params, responseType: 'json'})

        return response.data.data
    },
    async show(issuer_id) {
        const response = await axios.get(`/api/issuers/${issuer_id}`, {responseType: 'json'})

        return response.data.data
    },
    async store(data) {
        const response = await axios.post(`/api/issuers`, data, {responseType: 'json'})

        return response.data
    },
    async update(data) {
        const response = await axios.patch(`/api/issuers/${data.id}`, data, {responseType: 'json'})

        return response.data.data
    },
}

export default Issuer