/**
 * Part of Genesis application
 *
 * @copyright 2022 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

export const TransferInvoicingVoucher = {
    async store(freight_id, invoice_id) {
        const response = await axios.post(`/api/freights/${freight_id}/transfer_invoicing_vouchers`, {invoice_id}, {responseType: 'json'})

        return response.data.data
    },
    async update(data) {
        const request_data = {
            vehicle_id: data.vehicle_id,
            trailer_id: data.trailer_id,
            second_trailer_id: data.second_trailer_id,
            notes: data.notes,
            vehicle_gross_weight: data.vehicle_gross_weight,
        }
        await axios.patch(`/api/freights/${data.freight_id}/transfer_invoicing_vouchers/${data.transfer_invoicing_voucher_id}`, request_data)
    },
    async stamp(freight_id, transfer_invoicing_voucher_id) {
        await axios.patch(`/api/freights/${freight_id}/transfer_invoicing_vouchers/${transfer_invoicing_voucher_id}/stamp`)
    },
}

export default TransferInvoicingVoucher