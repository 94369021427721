/**
 * Part of Genesis application
 *
 * @copyright 2022 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

export const Query = {
    async list(params) {
        const response = await axios.get('/api/queries', {responseType: 'json', params})

        return response.data.data
    },
    async export(params) {
        await axios.post('/queries/export', params)
    },
    async show(query_type, params) {
        return await axios.get(`/api/queries/${query_type}`, {responseType: 'blob', params})
    },
}

export default Query