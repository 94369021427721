/**
 * Part of Genesis application
 *
 * @copyright 2022 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

export const FiscalVoucherDownload = {
    async store(data) {
        const response = await axios.post('/api/fiscal_voucher_downloads', data, {responseType: 'json'})
        return response.data.data
    },
}

export default FiscalVoucherDownload