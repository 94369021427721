/**
 * Part of Genesis application
 *
 * @copyright 2024 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

export const Invoice = {
    async delete(freight_id, seal_id) {
        await axios.delete(`/api/freights/${freight_id}/seals/${seal_id}`)
    },
    async list(freight_id) {
        const response = await axios.get(`/api/freights/${freight_id}/seals`)

        return response.data.data
    },
    async store(freight_id, data) {
        await axios.post(`/api/freights/${freight_id}/seals`, data, {responseType: 'json'})
    },
}

export default Invoice