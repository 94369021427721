/**
 * Part of Genesis application
 *
 * @copyright 2023 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

export const EdiLocation = {
    async store(locality_id, data) {
        const result = await axios.post(`/api/freight_localities/${locality_id}/edi_locations`, data, {responseType: 'json'})

        return result.data
    },
}

export default EdiLocation