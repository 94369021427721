/**
 * Part of Genesis application
 *
 * @copyright 2021 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

export const MeasurementUnit = {
    async list(term) {
        const response = await axios.get('/api/measurement_units/search', { params: { term }, responseType: 'json' })
        return response.data.data
    },
}

export default MeasurementUnit