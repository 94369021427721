<template>
    <div>
        <div class="columns">
            <div class="column is-2">
                <span class="subtitle">{{ $t('loadTender.stop_offs.headerColumns.sequence') }}</span>
            </div>
            <div class="column is-5">
                <span class="subtitle">{{ $t('loadTender.stop_offs.headerColumns.address') }}</span>
            </div>
            <div class="column is-5">
                <span class="subtitle">{{ $t('loadTender.stop_offs.headerColumns.locality') }}</span>
            </div>
        </div>
        <div
            class="columns mb-0"
            v-for="stop_off in loadTender.data.stop_offs"
            :key="stop_off.edi_location_id"
        >
            <div class="column is-2"><span>#{{ stop_off.sequence }}</span></div>
            <div class="column is-5"><span>{{ stopOffAddress(stop_off) }}</span></div>
            <div class="column is-5">
                <span v-if="stop_off.locality_id">{{ localityName(stop_off.locality_id) }}</span>
                <v-select
                    v-else
                    :append-to-body="setPopper"
                    :calculate-position="withPopper"
                    label="name"
                    :options="localities"
                    :placeholder="$t('loadTender.stop_offs.locality_id')"
                    :reduce="o => o.id"
                    :ref="'stop_off' + stop_off.edi_location_id"
                    :uid="'stop_off' + stop_off.edi_location_id"
                    @input="value => onLocalityInput(value, stop_off)"
                />
            </div>
        </div>
    </div>
</template>
<script>
import {createPopper} from '@popperjs/core'
import cloneDeep from 'lodash/cloneDeep'
import Genesis from '../../../services/genesis'
import VSelect from 'vue-select'

export default {
    name: 'LoadTenderStopOffsForm',
    components: {VSelect},
    props: {
        loadTender: {
            type: Object,
            required: true,
        },
        setPopper: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            localities: [],
            stop_offs: [],
        }
    },
    async created() {
        await listLocalities.call(this)

        this.stop_offs = cloneDeep(this.loadTender.data.stop_offs)
    },
    methods: {
        localityName(locality_id) {
            const locality = this.localities.find(o => o.id === locality_id)
            return locality ? locality.name : ''
        },
        onLocalityInput(value, stop_off) {
            const option = this.stop_offs.find(o => o.edi_location_id === stop_off.edi_location_id)
            option.locality_id = value
            this.$emit('selectLocalities', this.stop_offs)
        },
        stopOffAddress(stop_off) {
            return `${stop_off.address} ${stop_off.city} ${stop_off.geo_state} ${stop_off.country}`
        },
        withPopper(dropdownList, component, {width}) {
            if (this.setPopper) {
                /**
                 * We need to explicitly define the dropdown width since
                 * it is usually inherited from the parent with CSS.
                 */
                dropdownList.style.width = width

                /**
                 * Here we position the dropdownList relative to the $refs.toggle Element.
                 *
                 * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
                 * the dropdownList overlap by 1 pixel.
                 *
                 * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
                 * wrapper so that we can set some styles for when the dropdown is placed
                 * above.
                 */
                const popper = createPopper(component.$refs.toggle, dropdownList, {
                    placement: 'top',
                    modifiers: [
                        {
                            name: 'offset', options: {
                                offset: [0, -1],
                            },
                        },
                        {
                            name: 'toggleClass',
                            enabled: true,
                            phase: 'write',
                            fn({state}) {
                                component.$el.classList.toggle('drop-up', state.placement === 'top')
                            },
                        }],
                })

                /**
                 * To prevent memory leaks Popper needs to be destroyed.
                 * If you return function, it will be called just before dropdown is removed from DOM.
                 */
                return () => popper.destroy()
            }
        },
    },
}

async function listLocalities() {
    try {
        this.localities = await Genesis.catalog.locality.list(this.loadTender.customer_group_id, '', true)
    } catch (error) {
        await this.$store.dispatch('handleAjaxError', {
            error,
            message_key: 'loadTender.stop_offs.listLocalitiesError',
        })
    }
}

</script>