var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('grid',{staticStyle:{"height":"100%"},attrs:{"data-items":_vm.values,"columns":_vm.gridColumns},scopedSlots:_vm._u([{key:"actions-cell",fn:function(ref){
var props = ref.props;
return [_c('actions-cell',{attrs:{"data-item":props.dataItem,"actions":[
                {
                    action: 'load_tenders',
                    titleKey: 'loadTenders.loadTenderHistory.grid.actions.load_tender.title',
                    labelKey: 'loadTenders.loadTenderHistory.grid.actions.load_tender.label',
                    iconClasses: ['fas','fa-download'],
                    isVisible: function () { return true; },
                },
                {
                    action: 'load_tender_responses',
                    titleKey: 'loadTenders.loadTenderHistory.grid.actions.load_tender_response.title',
                    labelKey: 'loadTenders.loadTenderHistory.grid.actions.load_tender_response.label',
                    iconClasses: ['fas','fa-download'],
                    isVisible: function (dataItem) { return dataItem.load_tender_response_edi_transaction_id !== null; },
                },
                {
                    action: 'functional_acknowledgements',
                    titleKey: 'loadTenders.loadTenderHistory.grid.actions.functional_acknowledgement.title',
                    labelKey: 'loadTenders.loadTenderHistory.grid.actions.functional_acknowledgement.label',
                    iconClasses: ['fas','fa-download'],
                    isVisible: function () { return true; },
                } ],"actions-callback":function (action) {
                _vm.$emit('action-dispatched', { action: action, dataItem:props.dataItem })
            }}})]}}])},[_c('grid-no-records',[_vm._v("\n        "+_vm._s(_vm.$t('noResultsFound'))+"\n    ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }