/**
 * Part of Genesis application
 *
 * @copyright 2021 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

export const CustomerVariant = {
    async list() {
        const response = await axios.get('/api/customer_variants', {responseType: 'json'})
        return response.data.data
    },
    async viewModel() {
        const response = await axios.get('/api/customer_variants/view_model', {responseType: 'json'})
        return response.data
    },
}

export default CustomerVariant