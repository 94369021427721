/**
 * Part of Genesis application
 *
 * @copyright 2021 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import assignIn from 'lodash/assignIn'
import { ToastProgrammatic as Toast } from 'buefy'

export function showAlert(payload) {
    const is_message = typeof payload === 'string'
    const defaultConfiguration = {
        message: is_message ? payload : payload.message,
        position: 'is-top',
        type: 'is-success',
        duration: 4000,
        actionText: null,
    }

    assignIn(defaultConfiguration, is_message ? {} : payload)
    Toast.open(defaultConfiguration)
}

export default showAlert