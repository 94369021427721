/**
 * Part of Genesis application
 *
 * @copyright 2021 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */


import axios from 'axios'

export const PostalCode = {
    async list(geo_state) {
        const response = await axios.get('/api/catalogs/postal_codes', { params: { geo_state }, responseType: 'json' })

        return response.data.data
    },
    async get(postal_code, geo_state) {
        const response = await axios.get(
            `/api/postal_codes/${postal_code}`,
            {
                params: { geo_state },
                responseType: 'json',
            },
        )

        return response.data.data
    },
}

export default PostalCode