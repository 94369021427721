/**
 * Part of Genesis application
 *
 * @copyright 2021 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import axios from 'axios'

export const TrailerOwner = {
    async get(trailer_owner_id) {
        const response = await axios.get(`/api/trailer_owners/${trailer_owner_id}`, {responseType: 'json'})

        return response.data.data
    },
    async list(params = {}) {
        const response = await axios.get('/api/trailer_owners', {params, responseType: 'json'})

        return response.data.data
    },
    async store(data) {
        const response = await axios.post('/api/trailer_owners', data, {responseType: 'json'})

        return response.data
    },
    async update(trailerOwner) {
        const response = await axios.patch(`/api/trailer_owners/${trailerOwner.id}`, trailerOwner, {responseType: 'json'})

        return response.data.data
    },
}

export default TrailerOwner