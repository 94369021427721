<template>
    <b-field
        :label="label"
    >
        <b-input
            ref="textarea"
            v-model="internalValue"
            :maxlength="maxlength"
            type="textarea"
            @input="onTextareaInput"
        />
    </b-field>
</template>
<script>
export default {
    name: 'GenesisTextarea',
    props: {
        maxlength: {
            type: [ String, Number ],
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        value: {
            type: [ String, Number ],
            default: '',
        },
    },
    data() {
        return {
            internalValue: '',
        }
    },
    watch: {
        value(newValue) {
            this.internalValue = newValue
        },
    },
    created() {
        this.internalValue = this.value
    },
    methods: {
        onTextareaInput(value) {
            this.$emit('input', value)
        },
    },
}
</script>
