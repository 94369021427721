/**
 * Part of Genesis application
 *
 * @copyright 2021 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import assignIn from 'lodash/assignIn'

export function getSelectFilter(oldFilter, newFilter, field) {
    const filter = assignIn({
        filters: [],
        logic: 'and',
    }, oldFilter)

    const filter_index = filter.filters.findIndex(i => i.field === field)
    if (filter_index !== -1) {
        if (newFilter.value === -1) {
            filter.filters.splice(filter_index, 1)
        } else {
            filter.filters[filter_index].value = newFilter.value
        }
    } else {
        filter.filters.push(newFilter)
    }

    return filter
}

export function getKendoGridFilter(oldFilter, newFilter, field) {
    const filter = assignIn({
        filters: [],
        logic: 'and',
    }, oldFilter)

    const filter_index = filter.filters.findIndex(i => i.field === field)
    if (filter_index !== -1) {
        if (
            newFilter.value === null ||
            newFilter.value.length === 0 ||
            (typeof newFilter.value === 'string' && newFilter.value.trim() === '')
        ) {
            filter.filters.splice(filter_index, 1)
        } else {
            filter.filters[filter_index].value = newFilter.value
        }
    } else {
        newFilter.value = newFilter.value && typeof newFilter.value === 'string' ?
            newFilter.value.trim() :
            newFilter.value
        filter.filters.push(newFilter)
    }

    return filter
}

export function getDateFilter(oldFilter, newFilter, field) {
    const filter = assignIn({
        filters: [],
        logic: 'and',
    }, oldFilter)

    const filter_index = filter.filters.findIndex(i => i.field === field)
    if (filter_index !== -1) {
        if (newFilter.operator === 'inRange' && newFilter.value.start === null && newFilter.value.end === null) {
            filter.filters.splice(filter_index, 1)
        } else {
            filter.filters[filter_index] = newFilter
        }
    } else {
        if (newFilter.operator !== 'inRange' || newFilter.value.start || newFilter.value.end) {
            filter.filters.push(newFilter)
        }
    }

    return filter
}

export function transformKendoFilterToGenesisFilter(filter) {
    if (! filter) {
        return
    }

    const filterModel = {}
    filter.filters.forEach((filter_item) => {
        if (filter_item.value.length === 0) {
            return
        }

        switch (filter_item.operator) {
            case 'inRange':
            case 'today':
            case 'week':
            case 'month':
                filterModel[filter_item.field] = {
                    type: filter_item.operator,
                    dateFrom: filter_item.value.start,
                    dateTo: filter_item.value.end,
                }
                break
            case 'contains':
            case 'equals':
            case 'startsWith':
                filterModel[filter_item.field] = {
                    filter: filter_item.value,
                    type: filter_item.operator,
                }
                break
            case 'simple':
                filterModel[filter_item.field] = {
                    values: filter_item.value,
                    type: filter_item.operator,
                }
                break
            case 'eq':
                filterModel[filter_item.field] = {
                    values: [filter_item.value],
                    type: filter_item.operator,
                }
                break
        }
    })

    return filterModel
}

export function transformKendoSortToGenesisSort(sort) {
    if (! sort || sort.length === 0) {
        return
    }

    return sort.map(sort_item => {
        return {
            sort: sort_item.dir,
            colId: sort_item.field,
        }
    })
}

export default {
    getSelectFilter,
}