<template>
    <div class="is-height100pct">
        <nav-bar
            v-if="! isPopup"
            :notifications="notifications"
            :app-name="appName"
            @action-dispatched="onNavBarActionDispatched"
        />
        <section class="section is-height100pct">
            <slot/>
        </section>
        <feedback-modal
            v-if="! isPopup"
            ref="feedbackModal"
            modal-id="feedback-modal"
            :class="{ 'is-active': isModalActive('feedback-modal') }"
            feedback-url="/api/feedback/store"
        />
        <confirmation-modal
            v-if="! isPopup"
            ref="confirmationModal"
            modal-id="confirmation-modal"
            :class="{ 'is-active': isModalActive('confirmation-modal') }"
            @confirm="onConfirmModalConfirm"
        />
        <load-tender-window
            v-if="isLoadTenderWindowVisible"
            ref="loadTenderWindow"
            :values="pendingLoadTenders"
            @close="onLoadTenderWindowClose"
            @responseLoadTender="onLoadTenderWindowResponseLoadTender"
        />
        <b-modal
            ref="responseLoadTenderBModal"
            v-model="responseLoadTenderModalIsActive"
            has-modal-card
        >
            <template #default="props">
                <response-load-tender-modal
                    :reservation-action-code="reservation_action_code"
                    :load-tender="loadTender"
                    @close="props.close"
                    @confirm="onResponseLoadTenderConfirm"
                />
            </template>
        </b-modal>
    </div>
</template>
<script>
import {i18n} from '../setup/i18n-setup'
import {mapGetters} from 'vuex'
import ConfirmationModal from './ConfirmationModal'
import FeedbackModal from './FeedbackModal'
import helpers from '../helpers'
import LoadTenderResponse from '../services/loadTenderResponse'
import LoadTenderWindow from '../traffic/components/load_tender/LoadTenderWindow'
import NavBar from './NavBar'
import ResponseLoadTenderModal from '../traffic/components/load_tender/ResponseLoadTenderModal'
import UserService from '../services/UserService'
import {load, loadMessages} from '@progress/kendo-vue-intl'
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json'
import timeZoneNames from 'cldr-dates-full/main/es/timeZoneNames.json'
import weekData from 'cldr-core/supplemental/weekData.json'
import caGregorian from 'cldr-dates-full/main/es/ca-gregorian.json'
import dateFields from 'cldr-dates-full/main/es/dateFields.json'

load(likelySubtags, weekData, caGregorian, dateFields, timeZoneNames)

export default {
    name: 'Application',
    components: {
        LoadTenderWindow,
        ResponseLoadTenderModal,
        NavBar,
        FeedbackModal,
        ConfirmationModal,
    },
    props: {
        appName: {
            type: String,
            default: 'Génesis',
        },
    },
    data: function () {
        return {
            isLoadTenderWindowVisible: false,
            loadTender: null,
            notifications: [],
            reservation_action_code: null,
            responseLoadTenderModalIsActive: false,
        }
    },
    computed: {
        ...mapGetters(['isModalActive']),
        lang: function () {
            return i18n.locale
        },
        pendingLoadTenders() {
            return this.notifications.filter(n => n.type === 'App\\Notifications\\LoadTenderReceived')
                .map(n => n.data)
        },
        isPopup() {
            return this.$store.state.route && this.$store.state.route.query && this.$store.state.route.query.popup
        },
    },
    async created() {
        loadMessages(this.$i18n.messages, this.$i18n.locale)
        if (Laravel.user) {
            Echo.private('App.Genesis')
                .listen('FiscalVoucherCancelled', (payload) => this.onEchoEvent({
                    type: 'Echo/FiscalVoucherCancelled',
                    params: [payload],
                }))
                .listen('FiscalVoucherStamped', (payload) => this.onEchoEvent({
                    type: 'Echo/FiscalVoucherStamped',
                    params: [payload],
                }))
                .listen('FreightStatusChange', (payload) => this.onEchoEvent({
                    type: 'Echo/FreightStatusChange',
                    params: [payload],
                }))
                .listen('FreightTripStatusChange', (payload) => this.onEchoEvent({
                    type: 'Echo/FreightTripStatusChange',
                    params: [payload],
                }))
                .listen('LadingBillCreated', (payload) => this.onEchoEvent({
                    type: 'Echo/LadingBillCreated',
                    params: [payload],
                }))
                .listen('LoadTenderResponseCreated', this.onLoadTenderResponseCreatedEvent)
                .notification(this.onEchoNotification)
            const userChannel = `App.User.${Laravel.user.id}`
            Echo.private(userChannel)
                .notification(this.onEchoNotification)
            this.notifications = await UserService.fetchUnreadNotifications(Laravel.user.id)
        }
    },
    mounted() {
        this.$store.commit('EventModule/registerEventCallback', {
            type: 'onFreightCreated',
            key: 'Application',
            callback: this.onFreightCreatedEvent,
        })
        this.$store.commit('EventModule/registerEventCallback', {
            type: 'onLoadTenderRejected',
            key: 'Application',
            callback: this.onLoadTenderRejectedEvent,
        })
    },
    async beforeDestroy() {
        this.$store.commit('EventModule/unregisterEventCallback', {
            type: 'onFreightCreated',
            key: 'Application',
        })
        this.$store.commit('EventModule/unregisterEventCallback', {
            type: 'onLoadTenderRejected',
            key: 'Application',
        })
        await this.$store.dispatch('closeGpsService')
    },
    methods: {
        onConfirmModalConfirm: function (params) {
            params.callback(params.values)
        },
        onEchoEvent(payload) {
            this.$store.dispatch('EventModule/dispatchEvent', payload)
        },
        onEchoNotification(notification) {
            let type
            switch (notification.type) {
                case Laravel.Events.ServiceRequestFileProcessed:
                case Laravel.Events.ServiceRequestFileStatusChange:
                    type = 'ServiceRequestFileStatusChange'
                    break
                case Laravel.Events.CatalogFileStatusChange:
                    type = 'CatalogFileStatusChange'
                    break
                default:
                    type = 'Notification'
                    break
            }
            this.onEchoEvent({
                type: `Echo/${type}`,
                params: [notification],
            })

            const notifications = this.notifications.filter(n =>
                n.type !== 'App\\Notifications\\LoadTenderReceived' ||
                ! n.data ||
                ! notification.data ||
                n.data.shipment_id !== notification.data.shipment_id ||
                n.data.customer_group_id !== notification.data.customer_group_id,
            )

            this.notifications = notifications.concat([notification])

            if (notification.type === 'App\\Notifications\\AttachmentReady') {
                helpers.showAlert({
                    message: this.$t('freight_movement.gridExport.ready', {url: notification.url}),
                    type: 'is-success',
                    duration: 8000,
                })
            }
        },
        onFreightCreatedEvent(freight, name) {
            helpers.showAlert(this.$t('freight.freightDetail.saveSuccess', {freight_id: freight.id}))
            this.$store.dispatch('closeWindow', name)
            this.notifications = this.notifications
                .filter(n => n.type !== 'App\\Notifications\\LoadTenderReceived' ||
                    (
                        n.type === 'App\\Notifications\\LoadTenderReceived' &&
                        n.data.id !== freight.load_tender_id
                    ),
                )
        },
        onLoadTenderRejectedEvent(loadTender, name) {
            helpers.showAlert(this.$t('loadTender.loadTenderResponse.rejectSuccess'))
            this.$store.dispatch('closeWindow', name)
            this.notifications = this.notifications
                .filter(n => n.type !== 'App\\Notifications\\LoadTenderReceived' ||
                    (
                        n.type === 'App\\Notifications\\LoadTenderReceived' &&
                        n.data.id !== loadTender.id
                    ),
                )
        },
        onLoadTenderResponseCreatedEvent(payload) {
            this.onEchoEvent({
                type: 'Echo/LoadTenderResponseCreated',
                params: [payload],
            })
            this.notifications = this.notifications
                .filter(n => n.type !== 'App\\Notifications\\LoadTenderReceived' || (
                    payload.loadTenderResponse && n.data.id !== payload.loadTenderResponse.load_tender_id
                ))
        },
        onLoadTenderWindowClose() {
            this.isLoadTenderWindowVisible = false
        },
        onNavBarActionDispatched(payload) {
            switch (payload.action) {
                case Laravel.Genesis.TECHNICAL_SUPPORT_LABEL:
                    this.$store.dispatch('openModalWithParams', {
                        target: 'feedback-modal',
                        params: {feedback_type: payload.action},
                    })
                    break
                case 'notifications':
                    if (payload.data.type === 'App\\Notifications\\LoadTenderReceived') {
                        this.isLoadTenderWindowVisible = true
                        return
                    }
                    break
            }
        },
        onLoadTenderWindowResponseLoadTender(payload) {
            this.isLoadTenderWindowVisible = false
            this.responseLoadTenderModalIsActive = true
            this.loadTender = payload.loadTender
            this.reservation_action_code = payload.reservation_action_code
        },
        async onResponseLoadTenderConfirm(data) {
            try {
                await LoadTenderResponse.store({
                    load_tender_id: data.loadTender.id,
                    reservation_action_code: data.reservationActionCode,
                    load_tender_response_remarks: data.remarks,
                })
            } catch (error) {
                const contextError = {
                    error,
                    message_key: 'loadTender.loadTenderResponse.saveFailed',
                }

                if (error.response && error.response.status === Laravel.HttpStatusCode.UnprocessableEntity) {
                    contextError.details = []
                    contextError.configuration = {duration: 6000}
                    for (const key in error.response.data.errors) {
                        contextError.details = contextError.details.concat(error.response.data.errors[key])
                    }
                }
                await this.$store.dispatch('handleAjaxError', contextError)
                return
            }

            this.responseLoadTenderModalIsActive = false

            helpers.showAlert(this.$t('loadTender.loadTenderResponse.saveSuccess'))
        },
    },
}
</script>