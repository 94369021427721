/**
 * Part of Genesis application
 *
 * @copyright 2021 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'
import invoicingVoucher from './invoicingVoucher'

export const CreditNote = {
    invoicingVoucher,
    async apply(credit_note_id) {
        const response = await axios.patch(`/api/credit_notes/${credit_note_id}/apply`)

        return response.data.data
    },
    async get(credit_note_id) {
        const response = await axios.get(`/api/credit_notes/${credit_note_id}`)

        return response.data.data
    },
    async nullify(credit_note_id, data) {
        await axios.patch(`/api/credit_notes/${credit_note_id}/nullify`, data)
    },
    async recalculateDocuments(credit_note_id, data) {
        const response = await axios.post(`/api/credit_notes/${credit_note_id}/recalculate_documents`, data)

        return response.data.data
    },
    async relateDocuments(credit_note_id, data) {
        const response = await axios.patch(`/api/credit_notes/${credit_note_id}/relate_documents`, data)

        return response.data.data
    },
    async removeDocuments(credit_note_id, data) {
        await axios.delete(`/api/credit_notes/${credit_note_id}/remove_documents`, {data, responseType: 'json'})
    },
    async stamp(credit_note_id) {
        await axios.patch(`/api/credit_notes/${credit_note_id}/stamp`, null, {responseType: 'json'})
    },
    async store(data) {
        const response = await axios.post(`/api/credit_notes`, data, {responseType: 'json'})
        return response.data.data
    },
    async update(data) {
        const response = await axios.patch(`/api/credit_notes/${data.id}`, data)

        return response.data.data
    },
    async viewModel() {
        const response = await axios.get('/api/credit_notes/view_model', {responseType: 'json'})

        return response.data
    },
}

export default CreditNote