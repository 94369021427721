/**
 * Part of Genesis application
 *
 * @copyright 2022 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

export const ExchangeRate = {
    async getCurrentExchangeRate() {
        const response = await axios.get('/api/exchange_rates/current_value', {responseType: 'json'})

        return response.data
    },
    async show(params) {
        const response = await axios.get('/api/exchange_rates', {params, responseType: 'json'})

        return response.data.data
    },
}

export default ExchangeRate