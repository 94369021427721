<template>
    <td>
        <div class="control buttons has-addons">
            <actions-cell-action
                v-for="action in visibleActions"
                :key="action.action"
                :action="action"
                :data-item="dataItem"
                :disabled="disabled"
                @click="action_clicked => actionsCallback(action_clicked)"
            />
        </div>
    </td>
</template>
<script>
import ActionsCellAction from './ActionsCellAction'

export default {
    name: 'ActionsCell',
    components: {ActionsCellAction},
    props: {
        dataItem: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        actions: {
            type: Array,
            required: true,
        },
        actionsCallback: {
            type: Function,
            required: true,
        },
    },
    computed: {
        visibleActions() {
            return this.actions.filter(a => a.isVisible(this.dataItem))
        },
    },
}
</script>
