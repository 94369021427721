/**
 * Part of Genesis application
 *
 * @copyright 2022 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import catalogFile from './catalogFile'
import locality from './locality'
import measurementUnit from './measurementUnit'
import postalCode from './postalCode'
import productService from './productService'
import settlement from './settlement'
import axios from 'axios'

export const Catalog = {
    catalogFile,
    locality,
    measurementUnit,
    postalCode,
    productService,
    settlement,

    async get(catalog_type) {
        const response = await axios.get(`/api/catalogs/${catalog_type}`, {responseType: 'json'})
        return response.data.data
    },
}

export default Catalog