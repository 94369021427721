var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('grid',{staticStyle:{"height":"100%"},attrs:{"data-items":_vm.items,"columns":_vm.gridColumns},scopedSlots:_vm._u([{key:"actions-cell",fn:function(ref){
var props = ref.props;
return [_c('actions-cell',{attrs:{"data-item":props.dataItem,"actions":[
                {
                    action: 'accept',
                    titleKey: 'loadTender.grid.actions.accept',
                    iconClasses: [
                        'fas',
                        'fa-check' ],
                    isVisible: function () { return true; },
                },
                {
                    action: 'reject',
                    titleKey: 'loadTender.grid.actions.reject',
                    iconClasses: [
                        'fas',
                        'fa-times'
                    ],
                    isVisible: function () { return true; },
                },
                {
                    action: 'view',
                    titleKey: 'loadTender.grid.actions.view',
                    iconClasses: [
                        'fas',
                        'fa-eye'
                    ],
                    isVisible: function () { return true; },
                } ],"actions-callback":function (action) {
                _vm.$emit('action-dispatched', { action: action, dataItem:props.dataItem })
            }}})]}}])},[_c('grid-no-records',[_vm._v("\n        "+_vm._s(_vm.$t('noResultsFound'))+"\n    ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }