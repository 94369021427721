/**
 * Part of Genesis application
 *
 * @copyright 2021 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

export const VehiclePermit = {
    async assignVehicles(data) {
        await axios.patch(
            `/api/vehicle_permits/${data.id}/assign_vehicles`,
            data,
            {responseType: 'json'},
        )
    },
    async get(id) {
        const response = await axios.get(`/api/vehicle_permits/${id}`, {responseType: 'json'})

        return {
            vehiclePermit: response.data.data,
            available_vehicles: response.data.available_vehicles,
        }
    },
    async list(params) {
        const response = await axios.get('/api/vehicle_permits', {responseType: 'json', params})
        return response.data.data
    },
    async listByVehicle(params) {
        const response = await axios.get('/api/vehicle_permits/by_vehicle', {responseType: 'json', params})

        return response.data.data
    },
    async store(data) {
        const response = await axios.post('/api/vehicle_permits', data, {responseType: 'json'})
        return response.data
    },
    async update(data) {
        const response = await axios.patch(`/api/vehicle_permits/${data.id}`, data, {responseType: 'json'})
        return response.data.data
    },
    async updateStatus(data) {
        const response = await axios.patch(
            `/api/vehicle_permits/${data.id}/update_status`,
            data,
            {responseType: 'json'},
        )
        return response.data.data
    },
    async viewModel() {
        const response = await axios.get('/api/vehicle_permits/view_model', {responseType: 'json'})
        return response.data
    },
}

export default VehiclePermit