/**
 * Part of Genesis application
 *
 * @copyright 2024 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

export const ServiceRequestFileLocality = {
    async list(service_request_id, service_request_file_id) {
        const response = await axios.get(
            `/api/service_requests/${service_request_id}/service_request_files/${service_request_file_id}/service_request_file_localities`,
            {responseType: 'json'},
        )

        return response.data.data
    },
}

export default ServiceRequestFileLocality