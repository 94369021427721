var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('kendo-grid',{ref:"grid",style:(_vm.styleObject),attrs:{"columns":_vm.gridColumns,"data-items":_vm.items,"filterable":true},on:{"scroll":_vm.scrollHandler},scopedSlots:_vm._u([{key:"actions-cell",fn:function(ref){
var props = ref.props;
return [_c('actions-cell',{attrs:{"data-item":props.dataItem,"actions":[
                {
                    action: 'accept',
                    titleKey: 'loadTenders.grid.actions.accept',
                    iconClasses: [
                        'fas',
                        'fa-check' ],
                    isVisible: function (dataItem) { return dataItem.status === _vm.Laravel.LoadTenderStatus.Received; },
                },
                {
                    action: 'reject',
                    titleKey: 'loadTenders.grid.actions.reject',
                    iconClasses: [
                        'fas',
                        'fa-times' ],
                    isVisible: function (dataItem) { return dataItem.status === _vm.Laravel.LoadTenderStatus.Received; },
                },
                {
                    action: 'view',
                    titleKey: 'loadTenders.grid.actions.view',
                    iconClasses: [
                        'fas',
                        'fa-eye' ],
                    isVisible: function () { return true; },
                } ],"actions-callback":function (action) {
                _vm.$emit('action-dispatched', { action: action, dataItem:props.dataItem })
            }}})]}},{key:"freightCell",fn:function(ref){
            var props = ref.props;
return [_c('freight-cell',{attrs:{"data-item":props.dataItem},on:{"click":function () { return _vm.onFreightCellClick(props.dataItem); }}})]}},{key:"freightStatusUpdateCell",fn:function(ref){
            var props = ref.props;
return [_c('freight-status-update-cell',{attrs:{"data-item":props.dataItem}})]}},{key:"selectFilter",fn:function(ref){
            var props = ref.props;
return [_c('multi-select-filter',{attrs:{"options":_vm.selectFilterOptions(props.field),"field":props.field},on:{"onChange":function (newFilter) { return _vm.onSelectFilterChange(newFilter, props.field); }}})]}},{key:"textFilter",fn:function(ref){
            var props = ref.props;
return [_c('text-filter',{attrs:{"field":props.field},on:{"input":function (newFilter) { return _vm.onSelectFilterChange(newFilter, props.field); }}})]}},{key:"dateRangeFilter",fn:function(ref){
            var props = ref.props;
return [_c('date-range-filter',{attrs:{"field":props.field},on:{"input":function (newFilter) { return _vm.onSelectFilterChange(newFilter, props.field); }}})]}}])},[_c('grid-no-records',[_vm._v("\n        "+_vm._s(_vm.is_loading && _vm.items.length === 0 ? _vm.$t('loading') : _vm.$t('noResultsFound'))+"\n    ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }