/**
 * Part of Genesis application
 *
 * @copyright 2024 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

export const CoordinatedTrip = {
    async delete(coordinated_trip_id) {
        await axios.delete(`/api/coordinated_trips/${coordinated_trip_id}`)
    },
    async liquidate(coordinated_trip_id) {
        await axios.patch(`/api/coordinated_trips/${coordinated_trip_id}/liquidate`)
    },
    async revert(coordinated_trip_id) {
        await axios.patch(`/api/coordinated_trips/${coordinated_trip_id}/revert`)
    },
    async show(coordinated_trip_id) {
        await axios.get(`/api/coordinated_trips/${coordinated_trip_id}`)
    },
}

export default CoordinatedTrip