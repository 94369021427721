/**
 * Part of Genesis application
 *
 * @copyright 2021 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

export function createBlobAnchor(response) {
    const headers = response.headers
    const blob = new Blob([response.data], {type: headers['content-type']})
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    return link
}

export default createBlobAnchor