<template>
    <div v-if="is_loaded"
    >
        <b-tabs
            ref="tabs"
            v-model="tabs"
            @input="onTabsInput"
        >
            <b-tab-item
                :label="$t('loadTenders.loadTenderHistory.tabs.content')"
                value="content"
            >
                <div ref="htmlContent"/>
            </b-tab-item>
            <b-tab-item
                :label="$t('loadTenders.loadTenderHistory.tabs.grid')"
                value="grid"
            >
                <load-tender-history-grid
                    ref="grid"
                    :values="load_tender_history"
                    @action-dispatched="onLoadTenderGridActionDispatched"
                ></load-tender-history-grid>
            </b-tab-item>
        </b-tabs>
    </div>
    <section
        v-else
        class="hero"
        :class="[ { 'is-danger': error_message }]"
    >
        <div class="hero-body">
            <div class="container has-text-centered">
                <p
                    ref="message"
                    class="title"
                >
                    {{ error_message ? error_message : $t('loading') }}
                </p>
                <p
                    v-if="error_message"
                    class="subtitle"
                >
                    <button
                        ref="retryButton"
                        class="button"
                        @click="onRetryButtonClick"
                    >
                        {{ $t('retry') }}
                    </button>
                </p>
            </div>
        </div>
    </section>
</template>
<script>
import fileDownload from '../../../file_download'
import Genesis from '../../../services/genesis'
import LoadTenderGrid from './Grid.vue'
import LoadTenderHistoryGrid from './LoadTenderHistory/LoadTenderHistoryGrid.vue'
import moment from 'moment'
import TrafficService from '../../../TrafficService'

export default {
    name: 'LoadTenderDetail',
    components: {LoadTenderGrid, LoadTenderHistoryGrid},
    props: {
        loadTender: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            content: null,
            error_message: null,
            is_loaded: false,
            load_tender_history: [],
            tabs: 'content',
        }
    },
    watch: {
        is_loaded(newValue) {
            if (newValue) {
                this.$nextTick(() => {
                    this.$refs.htmlContent.innerHTML = this.content
                    addLocalTime()
                })
            }
        },
    },
    async mounted() {
        await this.$store.dispatch('CatalogModule/loadCatalogs', [
            Laravel.CatalogType.LoadTenderStatus,
            Laravel.CatalogType.EdiTransactionSetPurposeCode,
        ])
        if (! this.loadTender) {
            setBreadcrumbsAndTitle.call(this)
        }
        await this.loadHtmlContent()
    },
    methods: {
        async onLoadTenderGridActionDispatched(payload) {
            const attachment_data = {
                load_tenders: {
                    attachment_type: Laravel.AttachmentType.ID_EDI_HTML,
                    entity_id: payload.dataItem.id,
                    entity_type: 'load_tenders',
                    filename: 'EDI_204.html',
                },
                load_tender_responses: {
                    attachment_type: Laravel.AttachmentType.ID_EDI_INTERCHANGE,
                    entity_id: payload.dataItem.load_tender_response_edi_transaction_id,
                    entity_type: 'edi_transactions',
                    filename: 'EDI_990.edi',
                },
                functional_acknowledgements: {
                    attachment_type: Laravel.AttachmentType.ID_EDI_INTERCHANGE,
                    entity_id: payload.dataItem.functional_acknowledgement_edi_transaction_id,
                    entity_type: 'edi_transactions',
                    filename: 'EDI_997.edi',
                },
            }[payload.action]

            let response
            try {
                response = await Genesis.attachment.show(
                    attachment_data.entity_type,
                    attachment_data.entity_id,
                    attachment_data.attachment_type,
                )
            } catch (error) {
                const errorContext = {
                    error,
                    message_key: 'attachments.show.fails',
                }
                if (error.response && error.response.status === Laravel.HttpStatusCode.UnprocessableEntity) {
                    errorContext.details = []
                    errorContext.configuration = {duration: 6000}
                    for (let key in error.response.data.errors) {
                        errorContext.details = errorContext.details.concat(error.response.data.errors[key])
                    }
                }

                await this.$store.dispatch('handleAjaxError', errorContext)
                return
            }

            const link = fileDownload.createBlobAnchor(response)
            link.download = attachment_data.filename
            link.click()
        },
        async loadHtmlContent() {
            const load_tender_id = this.loadTender ? this.loadTender.id : this.$route.params.id
            try {
                this.content = await TrafficService.getLoadTenderHtmlContent(load_tender_id)
            } catch (error) {
                this.error_message = this.$t('loadFailed') + ': ' + error.message
                return
            }
            this.is_loaded = true
        },
        onRetryButtonClick() {
            this.loadHtmlContent()
        },
        async onTabsInput(value) {
            if (value === 'grid') {
                const load_tender_id = this.loadTender ? this.loadTender.id : this.$route.params.id
                try {
                    this.load_tender_history = await Genesis.traffic.loadTender.listHistory(load_tender_id)
                } catch (error) {
                    await this.$store.dispatch('handleAjaxError', {
                        error,
                        message_key: 'loadTenders.loadTenderHistory.listFailed',
                    })
                }
            }
        },
    },
}

function setBreadcrumbsAndTitle() {
    this.$store.dispatch('BreadcrumbModule/setBreadcrumbsAndTitle', {
        breadcrumbs: [
            {
                route: 'load_tenders.index',
                title: this.$t('loadTenders.title'),
            },
        ],
        title: this.$t('loadTenders.show.title'),
    })
}

function addLocalTime(class_name = 'date_time_utc', format = 'YYYY-MM-DD HH:mm') {

    if (typeof moment !== 'function') {
        console.error('Moment undefined')
        return
    }

    const elements = document.getElementsByClassName(class_name)
    const timeElements = Array.prototype.filter.call(elements, (e) => e.nodeName === 'TIME')
    timeElements.forEach((e) => {
        e.innerHTML = e.innerHTML + ' (' + moment(e.dateTime).format(format) + ' Hora local)'
    })
}
</script>