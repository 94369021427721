/**
 * Part of Genesis application
 *
 * @copyright 2024 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

export const CoordinatedTrip = {
    async list(trailer_owner_id, params) {
        const response = await axios.get(`/api/trailer_owners/${trailer_owner_id}/coordinated_trips`, {
            params,
            responseType: 'json',
        })

        return response.data.data
    },
}

export default CoordinatedTrip