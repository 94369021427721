/**
 * Part of Genesis application
 *
 * @copyright 2023 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

export const PaymentItem = {
    async destroy(payment_id, payment_item_id, data) {
        await axios.delete(`/api/accounts_receivable/payments/${payment_id}/items/${payment_item_id}`, {data})
    },
    async download(payment) {
        return await axios.get(`/api/accounts_receivable/payments/${payment.id}/items/download`, {responseType: 'blob'})
    },
    async list(payment_id, data) {
        const response = await axios.get(`/api/accounts_receivable/payments/${payment_id}/items`, {params: data})

        return response.data.data
    },
    async store(payment_id, data) {
        const response = await axios.post(`/api/accounts_receivable/payments/${payment_id}/items`, data)

        return response.data
    },
    async storeMany(payment_id, data) {
        const response = await axios.post(`/api/accounts_receivable/payments/${payment_id}/items/store_many`, data)

        return response.data
    },
    async storeWithCsv(payment_id, data) {
        const formData = new FormData()
        formData.set('file', data.file.getRawFile(), data.file.name)
        formData.set('payment_lock_version', data.payment_lock_version)

        return await axios.post(
            `/api/accounts_receivable/payments/${payment_id}/items/store_with_csv`,
            formData,
        )
    },
    async update(payment) {
        const response = await axios.patch(
            `/api/accounts_receivable/payments/${payment.payment_id}/items/${payment.id}`,
            payment,
        )

        return response.data.data
    },
}

export default PaymentItem