/**
 * Part of Genesis application
 *
 * @copyright 2021 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

export function customizeAddressModel(objectValue, sourceValue, key, newObject) {
    if (key === 'city') {
        newObject.city_code = sourceValue.code
        return sourceValue.description
    }
    if (key === 'municipality') {
        newObject.municipality_code = sourceValue.code
        return sourceValue.description
    }
    if (key === 'settlement') {
        newObject.settlement_code = sourceValue.code
        return sourceValue.description
    }
}

export default {
    customizeAddressModel,
}