<template>
    <div>
        <load-tender-stop-offs-form
            :loadTender="loadTender"
            @selectLocalities="onFormSelectLocalities"
        />
        <button
            :class="['button', 'is-success', {'is-loading': is_loading}]"
            ref="save"
            :disabled="disableSaveButton"
            @click="onSaveButtonClick"
        >
            {{ $t('Save') }}
        </button>
        <button
            class="button"
            ref="cancel"
            @click="onCancelButtonClick"
        >
            {{ $t('Cancel') }}
        </button>
    </div>
</template>
<script>
import cloneDeep from 'lodash/cloneDeep'
import Genesis from '../../../services/genesis'
import LoadTenderStopOffsForm from './LoadTenderStopOffsForm.vue'
import VSelect from 'vue-select'

export default {
    name: 'LoadTenderStopOffs',
    components: {LoadTenderStopOffsForm, VSelect},
    props: {
        loadTender: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            is_loading: false,
            stop_offs: null,
        }
    },
    computed: {
        disableSaveButton() {
            return ! this.stop_offs || this.stop_offs.some(o => o.locality_id === null)
        },
    },
    methods: {
        onCancelButtonClick() {
            this.$emit('cancel')
        },
        onFormSelectLocalities(value) {
            this.stop_offs = cloneDeep(value)
        },
        async onSaveButtonClick() {
            let fails = false
            this.is_loading = true
            await Promise.all(
                this.stop_offs.map(async (item) => {
                    try {
                        await Genesis.traffic.ediLocation.store(item.locality_id, {
                            customer_group_id: this.loadTender.customer_group_id,
                            qualifier: item.qualifier,
                            edi_location_id: item.edi_location_id,
                        })
                    } catch (error) {
                        fails = true
                        const contextError = {
                            error,
                            message_key: 'loadTender.stop_offs.storeFailed',
                        }

                        if (error.response && error.response.status === Laravel.HttpStatusCode.UnprocessableEntity) {
                            let errors = []
                            for (let errorsKey in error.response.data.errors) {
                                errors = errors.concat(error.response.data.errors[errorsKey])
                            }
                            contextError.configuration = {duration: 6000}
                            contextError.details = errors
                        }
                        await this.$store.dispatch('handleAjaxError', contextError)
                    }
                }),
            )
            this.is_loading = false
            if (! fails) {
                const loadTender = cloneDeep(this.loadTender)
                loadTender.data.stop_offs = this.stop_offs
                this.$emit('save', loadTender)
            }
        },
    },
}
</script>