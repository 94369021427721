/**
 * Part of Genesis application
 *
 * @copyright 2020 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

function can(ability, target) {
    const permissions = Array.isArray(Laravel.permissions) ? Laravel.permissions : []
    const permission = ability + ' ' + target
    return (
        Array.isArray(permissions) &&
        (permissions.length > 0) &&
        ((permissions[0] === '* *') || (permissions.indexOf(permission) !== -1))
    )
}

export default {
    can,
}
