/**
 * Part of Genesis application
 *
 * @copyright 2021 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'
import FreightTripModel from '../../../traffic/models/FreightTrip'

export const FreightTrip = {
    async list(params) {
        const url = '/api/freight_trips'
        const response = await axios.get(url, { params: params, responseType: 'json' })
        return response.data.data
    },
    async activeList() {
        const response = await axios.get('/api/freight_trips/active_list', {responseType: 'json'})
        return response.data.data
    },
    async get(freight_trip_id, transform_shipment_resource = false) {
        const response = await axios.get(`/api/freight_movements/${freight_trip_id}`, { responseType: 'json' })

        return transform_shipment_resource ?
            FreightTripModel.transformToFreightTripResource(response.data.data) :
            response.data.data
    },
}

export default FreightTrip
