<template>
    <a
        v-if="action.type === 'link'"
        :class="action.buttonClasses || ['is-primary', 'is-outlined']"
        :href="action.url(dataItem)"
        :title="$t(action.titleKey)"
        class="button is-small"
        target="_blank"
    >
        <span class="icon"><i :class="action.iconClasses"></i></span><span v-if="label">{{ label }}</span>
    </a>
    <dropdown-action
        ref="dropdownAction"
        v-else-if="action.type === 'dropdown'"
        :actions="action.actions"
        :params="dataItem"
        @click="action_clicked => $emit('click', action_clicked)"
    />
    <button
        v-else
        :class="action.buttonClasses || ['is-primary', 'is-outlined']"
        :title="$t(action.titleKey)"
        class="button is-small"
        :disabled="disabled"
        @click="$emit('click', action.action)"
    >
        <span class="icon"><i :class="action.iconClasses"></i></span><span v-if="label">{{ label }}</span>
    </button>
</template>

<script>
import DropdownAction from '../DropdownAction.vue'

export default {
    name: 'ActionComponent',
    components: {DropdownAction},
    props: {
        action: {
            type: Object,
            required: true,
        },
        dataItem: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        label() {
            return this.action.labelKey ? this.$t(this.action.labelKey) : this.action.label || ''
        },
    },
}
</script>