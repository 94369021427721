/**
 * Part of Genesis application
 *
 * @copyright 2020 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import assignIn from 'lodash/assignIn'
import UserAuthorization from '../../UserAuthorization'
import FreightTripStatus from '../../FreightTripStatus'

const model = Laravel.Model.Shipment

const STATUS_PROPERTY_PERMISSIONS = {
    [Laravel.FreightMovementStatusType.ID_OPEN]: () =>
        UserAuthorization.can(Laravel.Ability.CANCEL_IN_OPEN_STATUS, model) ||
        UserAuthorization.can(Laravel.Ability.SET_FALSE_START_IN_OPEN_STATUS, model),
    [Laravel.FreightMovementStatusType.ID_IN_ORIGIN]: () =>
        UserAuthorization.can(Laravel.Ability.CANCEL_IN_ORIGIN_STATUS, model) ||
        UserAuthorization.can(Laravel.Ability.SET_FALSE_START_IN_ORIGIN_STATUS, model),
    [Laravel.FreightMovementStatusType.ID_IN_TRANSIT]: () => false,
    [Laravel.FreightMovementStatusType.ID_IN_PRECOS]: () => false,
    [Laravel.FreightMovementStatusType.ID_IN_CUSTOMS_MEX]: () =>
        UserAuthorization.can(Laravel.Ability.SET_FALSE_START_IN_CUSTOMS_MEX_STATUS, model) ||
        UserAuthorization.can(Laravel.Ability.SET_FULL_INSPECTION_MEX_IN_CUSTOMS_MEX_STATUS, model),
    [Laravel.FreightMovementStatusType.ID_IN_CUSTOMS_FULL_INSPECTION_MEX]: () => false,
    [Laravel.FreightMovementStatusType.ID_IN_CUSTOMS_USA]: () => false,
    [Laravel.FreightMovementStatusType.ID_IN_CUSTOMS_FULL_INSPECTION_USA]: () => false,
    [Laravel.FreightMovementStatusType.ID_IN_DESTINATION]: () => false,
    [Laravel.FreightMovementStatusType.ID_COMPLETE]: () => false,
    [Laravel.FreightMovementStatusType.ID_FALSE]: () => false,
    [Laravel.FreightMovementStatusType.ID_LOCKED]: () => false,
    [Laravel.FreightMovementStatusType.ID_CANCELED]: () => false,
    [Laravel.FreightMovementStatusType.ID_RESERVED]: () => false,
    [Laravel.FreightMovementStatusType.ID_STAMPED]: () => false,
    [Laravel.FreightMovementStatusType.ID_CLOSED]: () => false,
}

function isOriginEditable() {
    return UserAuthorization.can(Laravel.Ability.UPDATE_ORIGIN, model) &&
        (new FreightTripStatus(this.status)).allowsUpdate()
}

function isDestinationEditable() {
    if (! UserAuthorization.can(Laravel.Ability.UPDATE_DESTINATION, model) ||
        this._customer_group_id === null) {
        return false
    }

    return new FreightTripStatus(this.status).allowsUpdate()
}

function isStatusEditable() {
    if (! UserAuthorization.can(Laravel.Ability.UPDATE_FREIGHT_MOVEMENT_STATUS, model) ||
        this._customer_group_id === null) {
        return false
    }

    return STATUS_PROPERTY_PERMISSIONS[this.status]()
}

function isFreightMovementTypeEditable() {
    return UserAuthorization.can(Laravel.Ability.UPDATE_FREIGHT_MOVEMENT_TYPE, model) &&
        (new FreightTripStatus(this.status)).allowsUpdate()
}

function isDriverEditable() {
    return UserAuthorization.can(Laravel.Ability.UPDATE_FREIGHT_MOVEMENT_DRIVER, model) &&
        (new FreightTripStatus(this.status)).allowsUpdate()
}

function isSecondDriverEditable() {
    return UserAuthorization.can(Laravel.Ability.UPDATE_FREIGHT_MOVEMENT_DRIVER, model) &&
        (new FreightTripStatus(this.status)).allowsUpdate()
}

function isTractorEditable() {
    return UserAuthorization.can(Laravel.Ability.UPDATE_FREIGHT_MOVEMENT_TRACTOR, model) &&
        (new FreightTripStatus(this.status)).allowsUpdate()
}

function isTrailerEditable() {
    return UserAuthorization.can(Laravel.Ability.UPDATE_FREIGHT_MOVEMENT_TRAILER, model) &&
        (new FreightTripStatus(this.status)).allowsUpdate()
}

function isSecondTrailerEditable() {
    return UserAuthorization.can(Laravel.Ability.UPDATE_FREIGHT_MOVEMENT_TRAILER, model) &&
        (new FreightTripStatus(this.status)).allowsUpdate()
}

function isTicketEditable() {
    return UserAuthorization.can(Laravel.Ability.UPDATE_TICKET, model) &&
        (new FreightTripStatus(this.status)).allowsUpdate()
}

function isBorderCrossingEditable() {
    return UserAuthorization.can(Laravel.Ability.UPDATE_BORDER_CROSSING, model) &&
        (new FreightTripStatus(this.status)).allowsUpdate()
}

function isAuthorizationEditable() {
    return UserAuthorization.can(Laravel.Ability.UPDATE_FREIGHT_MOVEMENT_AUTHORIZATION, model) &&
        (new FreightTripStatus(this.status)).allowsUpdate()
}

function isCustomerBranchEditable() {
    if (! UserAuthorization.can(Laravel.Ability.UPDATE_BILLING_BRANCH, model) ||
        this._customer_group_id === null) {
        return false
    }

    return new FreightTripStatus(this.status).allowsUpdate()
}

function isEventEditable() {
    return this.tractor_id !== null && this.driver_id !== null &&
        UserAuthorization.can(Laravel.Ability.CREATE_FREIGHT_MOVEMENT_EVENT, model) &&
        ! Laravel.FreightMovementStatusType.StatusUpdateDisallowed.includes(this.status) &&
        (this.status !== Laravel.FreightMovementStatusType.ID_OPEN || this.can_create_event_in_open_status)
}

const PROPERTY_MAP = {
    status: isStatusEditable,
    origin_id: isOriginEditable,
    destination_id: isDestinationEditable,
    freight_movement_type_id: isFreightMovementTypeEditable,
    driver_id: isDriverEditable,
    second_driver_id: isSecondDriverEditable,
    tractor_id: isTractorEditable,
    trailer_id: isTrailerEditable,
    second_trailer_id: isSecondTrailerEditable,
    ticket: isTicketEditable,
    border_crossing_id: isBorderCrossingEditable,
    authorization: isAuthorizationEditable,
    origin_arrival: isEventEditable,
    origin_departure: isEventEditable,
    preinspection_arrival: isEventEditable,
    preinspection_departure: isEventEditable,
    local_customs_arrival: isEventEditable,
    local_customs_departure: isEventEditable,
    foreign_customs_arrival: isEventEditable,
    foreign_customs_departure: isEventEditable,
    destination_arrival: isEventEditable,
    customer_branch_id: isCustomerBranchEditable,
}

function baseFreightTrip() {
    return {
        id: null,
        freight_movement_type_id: null,
        origin_id: null,
        destination_id: null,
        border_crossing_id: null,
        driver_id: null,
        second_driver_id: null,
        tractor_id: null,
        customer_branch_id: null,
        trailer_id: null,
        second_trailer_id: null,
        trailer_name: null,
        is_extra: false,
        order: null,
        status: Laravel.FreightMovementStatusType.ID_OPEN,
        authorization: null,
        created_by: null,
        created_at: null,
        ticket: null,
        lock_version: null,
        started_at: null,
        can_create_event_in_open_status: false,
        events: [],
        comments: [],
    }
}

class FreightTrip {
    constructor(customer_group_id, data) {
        assignIn(this, baseFreightTrip(), data)
        this._customer_group_id = customer_group_id
    }

    isPropertyEditable(property) {
        try {
            return PROPERTY_MAP[property].call(this)
        } catch (e) {
            throw new Error(`Unknown property '${property}'.`)
        }
    }

    canBeCompleted() {
        const freightTripStatus = new FreightTripStatus(this.status)
        return freightTripStatus.allowsCompleteFreightTrip() &&
            this.tractor_id !== null &&
            this.driver_id !== null &&
            this.events.some(e => e.event_type_id === Laravel.FreightMovementEventType.ID_ORIGIN_DEPARTURE)
    }

    canCreateDeparturePass() {
        const freightTripStatus = new FreightTripStatus(this.status)
        return freightTripStatus.allowsCreateDeparturePass() &&
            this.tractor_id !== null &&
            this.driver_id !== null
    }

    static transformToFreightTripResource(shipmentResource) {
        const getElementData = (data, key) => shipmentResource && shipmentResource[data] ?
            shipmentResource[data][key] :
            null
        const getComments = () => {
            if (! shipmentResource.creacion_comentario_id) return []

            return [
                {
                    id: shipmentResource.creacion_comentario_id,
                    freight_movement_id: shipmentResource.traslado_id,
                    comment: shipmentResource.creacion_comentario_comentario,
                    created_at: shipmentResource.creacion_comentario_fecha_hora,
                    user: {
                        name: shipmentResource.creacion_comentario_usuario,
                    },
                },
            ]
        }
        return new FreightTrip(shipmentResource.cliente_id, {
            id: shipmentResource.traslado_id,
            freight_movement_type_id: shipmentResource.freight_movement_type.id,
            origin_id: shipmentResource.origin_locality.id,
            destination_id: shipmentResource.destination_locality.id,
            customer_branch_id: shipmentResource.customer_branch_id,
            border_crossing_id: getElementData('border_crossing', 'id'),
            driver_id: getElementData('freight_driver', 'id'),
            second_driver_id: shipmentResource.second_driver_id,
            tractor_id: getElementData('tractor', 'id'),
            trailer_id: getElementData('trailer', 'id'),
            trailer_name: getElementData('trailer', 'name'),
            second_trailer_id: shipmentResource.second_trailer_id,
            is_extra: false,
            order: shipmentResource.order,
            status: shipmentResource.last_status.id,
            authorization: shipmentResource.autorizacion,
            created_by: shipmentResource.user.id,
            created_at: null,
            ticket: shipmentResource.boleta,
            lock_version: shipmentResource.lock_version,
            started_at: shipmentResource.fecha_servicio,
            events: shipmentResource.events,
            full_inspection_mex_event_id: shipmentResource.full_inspection_mex_event_id,
            can_create_event_in_open_status: shipmentResource.can_create_event_in_open_status === true,
            departure_pass_attachment: shipmentResource.departure_pass_attachment,
            comments: getComments(),
        })
    }

    lastEventByType(event_type_id) {
        const events = this.events
            .filter(e => e.event_type_id === event_type_id)
        return events.length === 0 ? null : events.pop()
    }
}

export default FreightTrip
