/**
 * Part of Genesis application
 *
 * @copyright 2023 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'
import paymentItem from './paymentItem/index'

export const Payment = {
    paymentItem,
    async apply(payment) {
        await axios.patch(`/api/accounts_receivable/payments/${payment.id}/apply`)
    },
    async list(params) {
        const response = await axios.get('/api/accounts_receivable/payments', {params, responseType: 'json'})

        return response.data.data
    },
    async nullify(payment) {
        await axios.patch(`/api/accounts_receivable/payments/${payment.id}/nullify`, {lock_version: payment.lock_version})
    },
    async pendingInvoices(payment_id, params) {
        const response = await axios.get(`/api/accounts_receivable/payments/${payment_id}/pending_invoices`, {params})

        return response.data.data
    },
    async replace(payment) {
        const response = await axios.post(`/api/accounts_receivable/payments/${payment.id}/replace`)

        return response.data
    },
    async show(payment_id) {
        const response = await axios.get(`/api/accounts_receivable/payments/${payment_id}`)

        return response.data.data
    },
    async store(data) {
        const response = await axios.post('/api/accounts_receivable/payments', data)

        return response.data
    },
    async stamp(payment, request_data) {
        await axios.patch(`/api/accounts_receivable/payments/${payment.id}/stamp`, request_data)
    },
    async update(payment) {
        const response = await axios.patch(`/api/accounts_receivable/payments/${payment.id}`, payment)

        return response.data
    },
    async reopen(payment) {
        await axios.patch(`/api/accounts_receivable/payments/${payment.id}/reopen`)
    },
    async storeWithCep(data) {
        const formData = new FormData()
        formData.set('customer_id', data.customer_id)
        formData.set('file', data.file.getRawFile(), data.file.name)
        formData.set('force_relation', data.force_relation)
        const response = await axios.post('/api/accounts_receivable/payments/store_with_cep', formData,
            {
                responseType: 'json',
                headers: {'Content-Type': 'multipart/form-data'},
            },
        )

        return response.data
    },
}

export default Payment